import ClienteController from './clientes.controller';

routes.$inject = ['$stateProvider'];

export default function routes($stateProvider) {
  $stateProvider
    .state('clientes', {
      url: '/clientes',
      template: '<structure-manager></structure-manager>',
      controller: ClienteController,
      controllerAs: 'clienteCtrl',
      estrutura: require('./clientes.tmpl.json')
    })
    .state('clientes/cadastro', {
      url:'/clientes/cadastro',
      template: '<structure-manager></structure-manager>',
      controller: ClienteController,
      controllerAs: 'clienteCtrl',     
      estrutura: require('./clientes.tmpl.json')
    })
}

