routes.$inject = ['$stateProvider'];

export default function routes($stateProvider) {
  $stateProvider
    .state('listas', {
      url: '/listas',
      template: require('./lists.html'),
      controller: 'ListsCtrl',
      controllerAs: 'ListsCtrl'
    });
}