import Auth from 'isilver-auth-user';

class CabecalhoCtrl {
  constructor($rootScope, configSettings) {
    this.$rootScope = $rootScope;
    this.$rootScope.dispositivoMovel = window.screen.availWidth < 900;    
    
    Auth.validarLogin(configSettings.baseUrl).then(retorno => {
      $rootScope.logged = retorno;
      $rootScope.$apply();
    }).catch(erro => {
      $rootScope.logged = false;
      $rootScope.$apply();
    })
  }  


  fecharSistema() {
    Auth.fecharSistema();
    this.$rootScope.logged = false;    
    window.location.reload();
  }
}

CabecalhoCtrl.$inject = ['$rootScope', 'configSettings'];

export default CabecalhoCtrl;