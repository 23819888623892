import ImovelTipoController from './imoveis-tipos.controller';

routes.$inject = ['$stateProvider'];

export default function routes($stateProvider) {
  $stateProvider    
    .state('imoveis-tipos', {
      url:'/imoveis-tipos',
      template: '<structure-manager></structure-manager>',
      controller: ImovelTipoController,
      controllerAs: 'imovelTipoCtrl',     
      estrutura: require('./imoveis-tipos.tmpl.json')
    })
    .state('imoveis-tipos/cadastro', {
      url:'/imoveis-tipos/cadastro',
      template: '<structure-manager></structure-manager>',
      controller: ImovelTipoController,
      controllerAs: 'imovelTipoCtrl',     
      estrutura: require('./imoveis-tipos.tmpl.json')
    })
}

