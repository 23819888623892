import angular from 'angular';

import SearchHeaderFormDefaultController from './search-header-form-default.controller'
//import SelectFiltrosPesquisa from './selectFiltrosPesquisa.directive'

export default angular.module('app.searchHeaderFormDefault', [/*SelectFiltrosPesquisa*/])
.directive('searchHeaderFormDefault', function() {
  return {
      restrict: 'E',
      replace: 'true',
      template:  require('./search-header-form-default.html'),
      controller: SearchHeaderFormDefaultController,
      link: (scope, elem, attr) => {          
          scope.ordemFiltro = null;          
      }
  }
}).name;