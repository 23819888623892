import angular from 'angular';

import APIServ from 'isilver-services';
import EGFuncoes from 'isilver-structure-manager/structure-manager.functions';
import APIAjuFor from 'isilver-helpers-format';

import $ from 'jquery';

export default angular.module('structureManagerSearchList', [])
.directive('structureManagerSearchList', ['$rootScope', '$compile', function($rootScope, $compile) {
    return {
        restrict: 'E',
        replace: true,
        template: '',
        link: function(scope, elem) {
            
            let $rS = $rootScope;
            
            var pagina = APIServ.parametrosUrl()[0];
            var parametros = scope.estrutura;

            let posicaoBotoes = scope.estrutura.botoesAcoesItensConsultaPosicao != undefined ? scope.estrutura.botoesAcoesItensConsultaPosicao : 'esquerda';
            let classeBotoes = posicaoBotoes == 'superior' ? 'col-12' : 'col-12 col-sm-2';
            let classeLista = posicaoBotoes == 'superior' ? 'col-12' : 'col-12 col-sm-10';


            /******************CRIANDO OS BOTOES DAS FUNCOES *******************************************/
            let htmlBotoes = `
                <div class="${classeBotoes}"> `

            if ((parametros.funcaoDetalhar == undefined || parametros.funcaoDetalhar != 'desativada') && (parametros.ocultarDetalhes == undefined || !parametros.ocultarDetalhes)) {
                let funcaoDet = parametros.funcaoDetalhar != undefined ? parametros.funcaoDetalhar : 'detalhar';
                htmlBotoes += `
                    <button type="button" name="button" title="Ver Detalhes"  ng-click="${funcaoDet}($index)" class="btn btn-light">
                        <i class="fas" ng-class="{'fa-plus' : !item.exibirDetalhes, 'fa-minus':item.exibirDetalhes}"></i>
                    </button>`;
            }

            if ((parametros.funcaoAlterar == undefined || parametros.funcaoAlterar != 'desativada') && $rS[pagina]['acoes']['Alterar']) {
                let funcaoAlt = parametros.funcaoAlterar != undefined ? parametros.funcaoAlterar : 'alterar';
                htmlBotoes += `<button type="button" class="btn btn-info fas fa-edit" title="Alterar ${parametros.nomeUsual}" ng-click="${funcaoAlt}(item[campo_chave])"></button>`;

            }
            if ((parametros.funcaoExcluir == undefined || parametros.funcaoExcluir != 'desativada') && $rS[pagina]['acoes']['Excluir']) {
                let funcaoExc = parametros.funcaoExcluir != undefined ? parametros.funcaoExcluir : 'excluir';
                htmlBotoes += `<button type="button" class="btn btn-danger fas fa-trash-alt" title="Excluir ${parametros.nomeUsual}" ng-click="${funcaoExc}(item)"></button>`;
            }

            if (parametros.acoesItensConsulta != undefined) {
                angular.forEach(parametros.acoesItensConsulta, function(val, key) {
                    if (val == 'anexos') {
                        htmlBotoes += `<input-botao parametros="${val}"></input-botao>`; // EGFuncoes.montarBotao(parametros.anexos);
                        console.log(parametros.anexos);
                    } else if (val == 'diretiva' || (val.tipo != undefined && val.tipo == 'diretiva')) {
                        let nomeDiretiva = val.nomeDiretiva != undefined ? val.nomeDiretiva : key;
                        nomeDiretiva = APIAjuFor.variavelParaDiretiva(nomeDiretiva);
                        htmlBotoes += `<${nomeDiretiva} campo="${key}"></${nomeDiretiva}>`;
                    } else if (val.tipo == 'caixaSelecao') {
                        htmlBotoes += `<span class="form-inline"><input type="checkbox" ng-model="item.${key}" ng-click="selecionarItemConsulta(key, item)"></span>`;
                    } else {
                        htmlBotoes += `<input-botao parametros="${key}"></input-botao>`; // EGFuncoes.montarBotao(val);
                    }
                })
            }

            htmlBotoes += `
            </div>
            <br>`;
            if (parametros.acoesItensConsulta != undefined) {
                angular.forEach(parametros.acoesItensConsulta, function(valAcaoItemConsulta, acaoItemConsulta) {
                    //Incluido ao clicar no botao de arquivos anexos nos itens da consulta
                    if (acaoItemConsulta == 'anexos') {
                        htmlBotoes += `<arquivos-anexos ng-if="item.exibirAnexos" tela ='consulta'"></arquivos-anexos>`;
                    } else if (valAcaoItemConsulta.telaRelacionada != undefined && valAcaoItemConsulta.telaRelacionada) {
                        //Tem que ter uma diretiva com o mesmo nome da acao para montar os campos
                        var nomeDiretiva = APIAjuFor.variavelParaDiretiva(acaoItemConsulta);
                        htmlBotoes += `<${nomeDiretiva} ng-if="item.${acaoItemConsulta}"></${nomeDiretiva}>`;
                    }
                })
            }
            /*********************** FIM DOS BOTOES ******************************/

            /************************ CRIANDO OS CAMPOS DA LISTA **********************/
            let htmlCamposLista = `
                <div class="${classeLista}">                
                    <div class="row">`;

            //Aqui, ao inves de usar o repeat do angular, crio todos os elementos pois as informacoes sobre o campo estao no template js
            angular.forEach(parametros.listaConsulta, function(val, key) {

                //Mesclo os valores do campo na listaConsulta com o de campos
                val = val != 'diretiva' ? $.extend({}, APIServ.buscarValorVariavel(parametros.campos, key), val) : val;

                val.tipo = val.tipo != undefined ? val.tipo : '';

                if (val.tipo != "oculto") {
                    if (val.tipo == 'caixaSelecao') {
                        val['raizModelo'] = 'item';
                        val['padrao'] = 'true';
                        htmlCamposLista += montaHtml(parametros, key, val);
                    } else if (val.tipo == 'imagem') {
                        var tamanho = EGFuncoes.montarTamanhos(val);
                        htmlCamposLista += `<div class="col-12 campoItemConsulta ${tamanho.join(' ')}">`;
                        htmlCamposLista += val.texto != '' ? `<label class="col-md-2">${val.texto}: </label>` : '';
                        htmlCamposLista += `<img ng-src="{{item.${key}}}" class="img-responsive "></div>`;
                    } else if (val == 'diretiva') {
                        //console.log('diretiva');
                        let nomeDiretiva = APIAjuFor.variavelParaDiretiva(key);
                        htmlCamposLista += `<${nomeDiretiva}></${nomeDiretiva}>`;
                    } else {
                        //Fazendo esta comparação somente para o ng-class, posteriormente incluir rotina para qualquer atributo
                        let ngClasses = val.atributosDiv != undefined && val.atributosDiv['ng-class'] ? `ng-class="${val.atributosDiv['ng-class']}"` : ";"

                        var tamanho = EGFuncoes.montarTamanhos(val);
                        htmlCamposLista += `<div class="col-12 campoItemConsulta ${tamanho.join(' ')}" ${ngClasses}>`;
                        var texto = val.texto != '' ? val.texto + ':' : '';
                        htmlCamposLista += `${texto} <label>&nbsp{{item.${key}}}</label></div>`;
                    }
                }
            })
            htmlCamposLista += ` </div>
                </div>`;

            /***************************** FIM DOS CAMPOS DA LISTA  **********************************/



            let html = `
                <div class="itemConsulta col-12 bg-danger text-center" ng-if="listaConsulta.length == 0 && tela != 'cadastro'">
                    <h3>Nenhum Ítem Encontrado</h3>
                </div>
                <estrutura-paginacao></estrutura-paginacao>                
                <div class="conteudoBusca col-12 vs-repeat-custom-scroll" vs-repeat="{}" vs-size="1024" >
                    <div class="row itemConsulta div3 font12" ng-repeat="item in listaConsulta | filter:filtroResultado" ng-if="tela != 'cadastro'" indice="{{$index}}" vs-autoresize>`;

            if (posicaoBotoes == 'superior') {
                html += htmlBotoes + htmlCamposLista;
            } else if (posicaoBotoes == 'esquerda') {
                html += htmlCamposLista + htmlBotoes;
            }

            html += `
                <div class="col-12 fundoDetalheConsulta" ng-if="item.exibirDetalhes">
                    <div class="row">
                        <h4 class="campoItemConsulta text-center fundobranco col-12">Mais Informações</h4>`;

            //html += `<ng-bind-html class="col-12 div2" ng-bind-html="item.htmlDetalhe"></ng-bind-html>`;
            html += `<structure-manager-list-item-details></structure-manager-list-item-details>`;
            if (parametros.anexos != undefined) {
                html += `<arquivos-anexos tela="detalhes" chave-array="key"></arquivos-anexos>`;
            }

            html += `
                        </div>
                    </div>
            </div>`;

            if (parametros.acoesRodapeConsulta != undefined) {
                html += `<div class="col-12><div class="row">`;
                angular.forEach(parametros.acoesRodapeConsulta, function(val, key) {
                    html += montarBotao(val);
                })
                html += `</div></div>`;
                html += "<hr>"
            }
            html += '</div>{{tela}}';
            html += `<estrutura-paginacao></estrutura-paginacao>`;

            elem.html(html);            
            $compile(elem.contents())(scope);
        }
    }
}]).name;