import angular from 'angular';

import APIServ from 'isilver-services';
import $ from 'jquery';
import EGFuncoes from 'isilver-structure-manager/structure-manager.functions'

const SearchHeaderController = ($rootScope, $scope, $element, $compile) => {
    let $rS = $rootScope;

    var parametros = $rS.estrutura;
    let nomeFormConsulta = 'formCon' + parametros.raizModelo;
    let tipoConsulta = parametros.tipoConsulta != undefined ? parametros.tipoConsulta : 'camposDinamicos';
    //Vou por o acoes inicio consulta, quando houver
    let acoesInicioConsulta = '';
    if (parametros.acoesInicioConsulta != undefined) {
        angular.forEach(parametros.acoesInicioConsulta, function (val, key) {
            if (val == 'diretiva') {
                let nomeDiretivaIC = APIAjuFor.variavelParaDiretiva(key);
                acoesInicioConsulta += `<${nomeDiretivaIC}></${nomeDiretivaIC}>`;
            } else {
                acoesInicioConsulta += `<input-botao parametros="${key}" ng-if="tela=='consulta'"></input-botao>`; // ' EGFuncoes.montarBotao(val);
            }
        })
    }

    var pagina = APIServ.parametrosUrl()[0];

    $('.navbar-brand').html(parametros.textoPagina);

    let htmlFiltroResultado = `
        <div class="col-12 col-sm-3 form-group" ng-class="{'baixarbotao': !dispositivoMovel}">
            <input type="text" ng-change="alterarFiltroResultado(filtroResultadoTela)" ng-model="filtroResultadoTela" class="form-control" placeholder="Buscar nos Ítens da Tela">
        </div>`;
    let html =
        `<div class="row">
            <h2 class="col-12 col-sm-8" ng-if="!dispositivoMovel">${parametros.textoPagina}</h2>`;

    if (EGFuncoes.temCadastro(parametros) && $rS[pagina]['acoes']['Cadastrar']) {
        html += `<button class="col-12 col-sm-4 btn btn-success" ng-class="{'top10': !dispositivoMovel}" ng-if="tela != 'cadastro'" ng-click="mudaTela('cadastro')">${parametros.textoNovo}</button>`;
    }

    if (!$rS.popUp) {
        html += `<button class="col-12 col-sm-4 btn btn-primary" ng-class="{'top10': !dispositivoMovel}" ng-if="tela == 'cadastro'" ng-click="mudaTela('consulta')">Ir Para Consulta</button>`;
    }

    html += `
    </div>`;

    if (parametros.filtroPersonalzadoDiretiva != undefined) {
        html += `<${APIAjuFor.variavelParaDiretiva(parametros.filtroPersonalzadoDiretiva)} ng-if="tela != 'cadastro'"></${APIAjuFor.variavelParaDiretiva(parametros.filtroPersonalzadoDiretiva)}>`;
    } else {
        html += '<search-header-form-default></search-header-form-default>';
    }

    html += `
    <div class="col-12" ng-if="listaConsulta.length > 0">
        <div class="row">
            ${acoesInicioConsulta}
        </div>
    </div>
    <hr>

    <div class="col-12 resumoConsulta"  ng-if="resumoConsulta != undefined">
        <div class="row">
            <div class="col-12 col-md-4 divitemLista div6 itemResumoConsulta" ng-repeat="(key, val) in resumoConsulta">
                <span>{{estrutura.resumoConsulta[key]['texto']}}: <label>{{val}}</label></span>
            </div>
        </div>
    </div>`;

    html += `<div ng-init="filtrar(0)" ng-if="estrutura.tipoEstrutura == 'consultaDireta' && tela != 'cadastro'""></div>`;

    $element.html(html);    

    $compile($element.contents())($scope);
    
}

SearchHeaderController.$inject = ['$rootScope', '$scope', '$element', '$compile'];

export default SearchHeaderController;