import $ from 'jquery';
import jQuery from 'jquery';
import APIServ from '../isilver-services';
import APIDialogs from '../isilver-dialogs';

// JavaScript Document
var baseUrl = APIServ.descriptgrafa(APIServ.buscarDadosLocais('baseUrl'));
var token = APIServ.buscarDadosLocais('token');
var url_classe = baseUrl + 'directCall';

jQuery.popularnomelista = function (obj, setar) {
    $('#' + obj).empty();
    $('#' + obj).append(new Option('Selecione a Lista', ''));
    $.getJSON(url_classe, {funcao_executar: 'jsonnomelistas'})
            .done(function (json) {
                $.each(json, function (chave, valor) {
                    $('#' + obj).append(new Option(valor, valor));
                });
            });
}

//Preenche a div com os itens da lista para gerenciamento
jQuery.listalistasgerenciamento = function (nomeLista, divlista) {
    $.get(url_classe, {
        funcao_executar: 'listalistasgerenciamento',
        parametros: nomeLista
    }).done(function (retorno) {
        $('#' + divlista).html(retorno);
    });
}

//Verifica se a lista existe, caso sim retorna a chave da lista, senao retorna 0;
jQuery.listaExiste = function (info) {
    var retorno = '';
    $.ajax({
        url: url_classe,
        async: false,
        type: 'GET',
        data: {
            funcaoExecutar: 'listaExiste',
            token: token,
            classe: 'listas',
            parametros: {
                nome: info.nomeLista,
                descricao: info.valor
            }
        }, success: function (res) {            
            retorno = res == 'S';
        },
        error: function (XMLHttpRequest, textStatus, errorThrown) {
            alert(errorThrown);
        }
    });
    return retorno;
}

jQuery.alterarlista = function (chave_lista, descricao) {
    $.get(url_classe, {
        funcao_executar: 'alteralista',
        parametros: {
            chave_lista: chave_lista,
            descricao: descricao
        }
    }).done(function (retorno) {
        if (retorno == 0) {
            $.modalSimples('Aten??o', 'Erro al alterar! Tente novamente.');
        } else {
            $('#div_' + chave_lista + ' .alterar').attr('disabled', true);
        }
    });
}

jQuery.excluirlista = function (chave_lista) {
    $.get(url_classe, {
        funcao_executar: 'excluilista',
        parametros: chave_lista
    }).done(function (retorno) {
        if (retorno == 0) {
            $('#div_' + chave_lista).remove();
        } else {
            var div_pai = '#div_' + chave_lista;
            var descricao = $(div_pai).children('.item_lista').val();
            var nomeLista = $('#nomeLista').val();
            $.montartelasubitituicao(nomeLista, chave_lista, descricao);
        }
    });
}

jQuery.montartelasubitituicao = function (nomeLista, chave_lista, descricao) {
    $.get(url_classe, {
        funcao_executar: 'montatelasubstituicao',
        parametros: {
            nomeLista: nomeLista,
            chave_lista: chave_lista,
            descricao: descricao
        }
    }).done(function (retorno) {
        $(retorno).appendTo($('#div_' + chave_lista));
    });
}

jQuery.substituirlista = function (nomeLista, old_chave, new_chave) {
    $.get(url_classe, {
        funcao_executar: 'substituilista',
        parametros: {
            nomeLista: nomeLista,
            old_chave: old_chave,
            new_chave: new_chave
        }
    }).done(function (retorno) {
        if (retorno == 1) {
            $('#div_' + old_chave).remove();
        } else {
            alert('Erro ao Substituir! Tente Novamente!');
        }
    });
}

jQuery.salvarLista = function (info) {
    console.log(info);
    var retorno = 0;
    $.ajax({
        url: url_classe,
        type: 'GET',
        async: false,
        data: {
            funcaoExecutar: 'inserirLista',
            token: token,
            classe: 'listas',            
            parametros: {
                nome: info.nomeLista,
                descricao: info.valor
            }
        },
        success: function (res) {
            retorno = res;
            /*
            if (retorno > 0) {
                $('#' + info.id_chave).val(retorno);
                angular.element(jQuery('#' + info.id)).triggerHandler('change');
                angular.element(jQuery('#' + info.id_chave)).triggerHandler('change');
            } else {
                $('#' + info.id).focus();
                alert('Erro ao Salvar ' + info.nomeApresenta + '. Tente novamente!');
            }
            $(this).removeAttr('salvandoLista');
            */
        }        
    });
    return retorno;

}
