import APIServ from 'isilver-services';
import APIDialogs from 'isilver-dialogs';
import $ from 'jquery';

function UserCtrl($scope) {
  $scope.usuario = APIServ.buscarDadosLocais('usuario');


  $scope.alterarSenha = function () {
    var baseUrl = APIServ.descriptgrafa(APIServ.buscarDadosLocais('baseUrl'));
    let token = APIServ.buscarDadosLocais('token');

    $.ajax({
      url: baseUrl + '/directCall/',
      type: 'GET',
      async: false,
      data: {
        funcaoExecutar: 'alterarSenha',
        token: token,
        classe: 'usuarios',
        parametros: {
          chave_usuario: $scope.usuario.chave_usuario,
          senha: $scope.usuario.senha
        }
      }
    }).then(function (retorno) {
      if (retorno.chave >= 0) {
        APIDialogs.mensagemSimples("Confirmação", 'Senha Alterada!')
      } else {
        APIDialogs.mensagemSimples('Informação', 'Erro ao Alterar a Senha');
      }
    })
  }
}

UserCtrl.$inject = ['$scope', 'configSettings'];

export default UserCtrl;