import angular from 'angular';
import uiRouter from 'angular-ui-router';

import routing from './imoveis.routes';
import ImovelController from './imoveis.controller';

import APIServ from 'isilver-services';

import StructureManager from 'isilver-structure-manager';

export default angular.module('app.imoveis', [uiRouter, StructureManager])
  .config(routing)
  .controller('ImovelController', ImovelController)
  .run(() => {
    APIServ.salvarEStrutura('imoveis', require('./imoveis.tmpl.json'));
  })
  .name;