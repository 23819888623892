import ImovelController from './imoveis.controller';

routes.$inject = ['$stateProvider'];

export default function routes($stateProvider) {
  $stateProvider
    .state('imoveis', {
      url: '/imoveis',
      template: '<structure-manager></structure-manager>',
      controller: ImovelController,
      controllerAs: 'imovelCtrl',
      estrutura: require('./imoveis.tmpl.json')
    })
    .state('imoveis/cadastro', {
      url:'/imoveis/cadastro',
      template: '<structure-manager></structure-manager>',
      controller: ImovelController,
      controllerAs: 'imovelCtrl',     
      estrutura: require('./imoveis.tmpl.json')
    })
}

