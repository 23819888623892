import APIServ from 'isilver-services';
import $ from 'jquery';

class MenuPainelCtrl{
  constructor($rootScope, configSettings){
    this.$rootScope = $rootScope;
  }
  
  manipularMenu() {  
    let ativo = $('#sidebar').hasClass('active');    

    console.log(ativo);
    

    if (ativo) {
      setTimeout(() => {
        $('#sidebar').removeClass('active');
        $('.overlay').removeClass('active');
        document.getElementById('textoBotaoMenu').innerHTML = 'Mostrar Menu';  
      }, 100);      
    } else {
      this.menus = APIServ.buscarDadosLocais('menus');
      setTimeout(() => {
        $('#sidebar').addClass('active');
        $('.overlay').addClass('active');
        $('.collapse.in').toggleClass('in');
        $('a[aria-expanded=true]').attr('aria-expanded', 'false');
        document.getElementById('textoBotaoMenu').innerHTML = 'Ocultar Menu';  
      }, 100);
      
    }
  }

  fecharMenu() {
    $('#sidebar').removeClass('active');
    $('.overlay').removeClass('active');
    document.getElementById('textoBotaoMenu').innerHTML = 'Mostrar Menu';  
  }
}

MenuPainelCtrl.$inject = ['$rootScope', 'configSettings'];

export default MenuPainelCtrl;

$(document).on('click', '#menu_backup', () => {
    let token = APIServ.buscarDadosLocais('token');
    window.open(configSettings.baseUrlApi + 'backup/fazerBackupBase/*/' + token , '_blank');
})