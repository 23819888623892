const axios = require('axios');

var redirecionarLogin = function () {
    if (window.location.hash != '#/login') {
        window.location.href = './#/login';
    }
}

var validarLogin = function (baseUrl) {
    return new Promise((resolve) => {
        let token = window.localStorage.getItem('token');

        if (token == undefined || token == null) {
            redirecionarLogin();
            resolve(false);
        } else {
            axios.get(baseUrl + 'validarToken/' + token).then(retorno => {    
                if (retorno.data < 0) {
                    window.localStorage.removeItem('token');
                    window.localStorage.removeItem('menus');
                    window.location.href = './#/login';
                } else {
                    resolve(true);
                }
            })
        }
    })
}


module.exports.validarLogin = validarLogin;

module.exports.fecharSistema = function () {
    window.localStorage.removeItem('token');
    window.localStorage.removeItem('menus');
    redirecionarLogin();
}
