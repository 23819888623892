import angular from 'angular';
import CabecalhoCtrl from './cabecalho.controller';
import './cabecalhoFuncoes';


function cabecalho() {
  return {
    restrict: 'E',
    template: require('./cabecalho.html'),
    controller: CabecalhoCtrl,
    controllerAs: 'cabCtrl'
  }
}

export default angular.module('directives.cabecalho', [])
  .directive('cabecalho', cabecalho)
  .name;