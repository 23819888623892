import angular from 'angular';

import HeaderSearch from '../isilver-search-header';
import StructureManagerSearchList from './structure-manager-search-list';
import SearchHeaderDefaultForm from '../isilver-search-header/search-header-form-default.directive';
import StructureManagerForm from './structure-manager-form';
import StructureManagerHtmlCreate from './structure-manager-html-create';
import InputMasks from '../isilver-input-masks';
import Selects from '../isilver-selects';
import AutoComplete from '../isilver-autocomplete';
import Lists from '../isilver-lists';
import ListItemDetails from './structure-manager-list-item-details';


import StructureManagerCtrl from './structure-manager.controller';
import StructureManagerLik from './structure-manager.link';



function structureManager() {
  return {
    restrict: 'E',
    controller: StructureManagerCtrl,
    link: StructureManagerLik
  }
}
export default angular.module('structureManager', [
  //AutoComleta,
  InputMasks,
  HeaderSearch, 
  SearchHeaderDefaultForm,
  StructureManagerSearchList,
  StructureManagerForm,
  StructureManagerHtmlCreate,
  Selects,
  AutoComplete,
  Lists,
  ListItemDetails
  //mascarasDirectives, 
  //validaFormulario
  ])
  .directive('structureManager', structureManager)
  .name;