import $ from 'jquery';

$(document).ready(function () {
  $('#dismiss, .linkMenu').on('click', function () {
    $('#sidebar').removeClass('active');
    $('.overlay').removeClass('active');
  });

  $('#sidebarCollapse').on('click', function () {
    let ativo = $('#sidebar').hasClass('active');

    if (ativo) {
      $('#sidebar').removeClass('active');
      $('.overlay').removeClass('active');
      document.getElementById('textoBotaoMenu').innerHTML = 'Mostrar Menu';
    } else {
      $('#sidebar').addClass('active');
      $('.overlay').addClass('active');
      $('.collapse.in').toggleClass('in');
      $('a[aria-expanded=true]').attr('aria-expanded', 'false');
      document.getElementById('textoBotaoMenu').innerHTML = 'Ocultar Menu';
    }
  });
});