const axios = require('axios');
const base64 = require('./apiservices-base64');

const _eObjeto = valor => {
    return typeof valor === 'object';
}

const _salvarDadosLocais = (nomeArmazenamento, dadosSalvar) => {
    return window.localStorage[nomeArmazenamento] = JSON.stringify(dadosSalvar);
}

const _buscarDadosLocais = (dadosBuscar) => {
    var dados = window.localStorage.getItem(dadosBuscar);
    try {
        return dados != undefined ? JSON.parse(dados) : undefined;
    } catch (e) {
        return dados != undefined ? dados : undefined;
    }
}

const _apagarDadosLocais = (nomeApagar, id) => {
    if (!id) {
        return window.localStorage.removeItem(nomeApagar);
    } else {
        var novo = {};
        var dados = buscarDadosLocais(nomeApagar);
        Object.keys(dados).map((key) => {
            if (key == id)
                delete dados[key];
        })
        return salvarDadosLocais(nomeApagar, dados);
    }
}

const _temClasse = (idElemento, classe) => {
    return document.querySelector('#' + idElemento).classList.contains(classe);
}

const _buscarValorVariavel = (variavel, chave, valor = '') => {
    if (variavel != undefined) {
        if (chave == undefined) { //No caso de precisar apenas passar o valor do scope sem vincular a variavel ao scope
            return variavel
        } else {
            var retorno = valor;
            Object.keys(variavel).map(key => {
                if (key == chave) {
                    retorno = variavel[key];
                } else if (_eObjeto(variavel[key]) && retorno == '') {
                    retorno = _buscarValorVariavel(variavel[key], chave);
                }
            })
            return retorno;
        }
    }
}

const _parametrosUrl = (separator = '#') => {
    var retorno = [];
    var temp = window.location.href;
    var temp1 = temp.split(separator);

    if (temp1.length > 1) {
        let te = temp1[0].split('/');
        var retornoTemp = temp1[1].split('/');
        Object.keys(retornoTemp).map(key => {
            if (retornoTemp[key] != '') {
                retorno.push(retornoTemp[key]);
            }
        });
    }
    return retorno;
};

const _valorExisteEmVariavel = function (variavel, valor, chave = '', existe = false) {
    var retorno = existe;
    Object.keys(variavel).map(key => {
        var val = variavel[key];

        if (_eObjeto(val) && !retorno) {
            retorno = chave != '' && key == chave ? true : valorExisteEmVariavel(val, valor, chave, retorno);
        } else {
            retorno = retorno ? retorno :
                chave != '' && key == chave && val == valor ? true :
                    chave == '' && val == valor ? true :
                        key == chave && valor == '' ? true : false;
        }
    })
    return retorno;
}

const _converteParametrosparaUrl = function (parametros) {
    if (angular.isObject(parametros)) {
        var retorno = {};
        angular.forEach(parametros, function (value, key) {
            if (!angular.isObject(value)) {
                retorno[key] = base64.encode(value).replace('/', '_-_');
            } else if (angular.isObject(value)) {
                retorno[key] = _converteParametrosparaUrl(value);
            }
        })
    } else {
        var retorno = parametros;
    }
    return retorno;
};

const _executaFuncaoClasse = function (baseUrl, classe, funcaoExecutar, parametros, tipo = 'get') {
    if (tipo == 'get') {
        var temp = _converteParametrosparaUrl(parametros);
        var parametrosEnviar = angular.isObject(temp) ? angular.toJson(temp) : temp;

        let token = _buscarDadosLocais('token');
        //console.log(baseUrl + '/' + classe + '/' + funcaoExecutar + '/' + parametrosEnviar + '/' + token);
        return axios.get(baseUrl + '/' + classe + '/' + funcaoExecutar + '/' + parametrosEnviar + '/' + token);
    } else if (tipo == 'post') {
        return axios.post(baseUrl + '/' + classe + '/' + funcaoExecutar, parametros, {
            transformRequest: angular.identity,
            headers: {
                'Content-Type': undefined
            }
        });
    }
}

const _transporVariavel = function (variavel, retorno = {}) {
    var retorno = retorno;
    angular.forEach(variavel, function (val, key) {
        if (angular.isObject(val)) {
            retorno[key] = val;
        } else {
            retorno[key] = val;
        }
    })
    return retorno;
}

const _primeiraMaiusculo = function (texto) {
    var primeira = texto.substr(0, 1);
    var restante = _minusculo(texto.substr(1, texto.length - 1));
    primeira = _maiusculo(primeira);
    return primeira + restante;
}

const _maiusculo = function (texto) {
    return texto != undefined ? texto.toUpperCase() : '';
}

const _minusculo = function (texto) {
    return texto != undefined ? texto.toLowerCase() : '';
}
const _limparAcentos = function (text, maimin, espaco) {
    text = text.replace(new RegExp('[ÁÀÂÃÄ]', 'g'), 'A');
    text = text.replace(new RegExp('[áàâãä]', 'g'), 'a');
    text = text.replace(new RegExp('[ÉÈÊË]', 'g'), 'E');
    text = text.replace(new RegExp('[éèêë]', 'g'), 'e');
    text = text.replace(new RegExp('[ÍÌÎÏ]', 'g'), 'I');
    text = text.replace(new RegExp('[íìîï]', 'g'), 'i');
    text = text.replace(new RegExp('[ÓÒÔÕÖ]', 'g'), 'O');
    text = text.replace(new RegExp('[óòôõö]', 'g'), 'o');
    text = text.replace(new RegExp('[ÚÙÛÜ]', 'g'), 'U');
    text = text.replace(new RegExp('[úùûü]', 'g'), 'u');
    text = text.replace(new RegExp('[Ç]', 'g'), 'C');
    text = text.replace(new RegExp('[ç]', 'g'), 'c');
    if (espaco == 'nao')
        text = text.replace(new RegExp('[ ]', 'g'), '');
    text = text.replace(new RegExp('[´`~^¨]', 'g'), '');
    //text = text.replace(new RegExp('[\/:*?"<>|]', 'g'), '');

    if (maimin == 'min') {
        return text.toLowerCase();
    } else if (maimin == 'mai') {
        return text.toUpperCase();
    } else {
        return text;
    }
}

const _salvarEstrutura = (nome, estrutura) => {
    let estruturas = JSON.parse(window.localStorage.getItem('estruturas'));    
    estruturas[nome] = estrutura;       
    window.localStorage.setItem('estruturas', JSON.stringify(estruturas));
}

module.exports = {
    eObjeto: _eObjeto,
    salvarDadosLocais: _salvarDadosLocais,
    buscarDadosLocais: _buscarDadosLocais,
    apagarDadosLocais: _apagarDadosLocais,
    temClasse: _temClasse,
    buscarValorVariavel: _buscarValorVariavel,
    parametrosUrl: _parametrosUrl,
    valorExisteEmVariavel: _valorExisteEmVariavel,
    executaFuncaoClasse: _executaFuncaoClasse,
    transporVariavel: _transporVariavel,
    criptografa: base64.encode,
    descriptgrafa: base64.decode,
    maiusculo: _maiusculo,
    minusculo: _minusculo,
    limparAcentos: _limparAcentos,
    primeiraMaiusculo: _primeiraMaiusculo,
    salvarEStrutura: _salvarEstrutura
};

