import angular from 'angular';
import uiRouter from 'angular-ui-router';

import routing from './imoveis-tipos.routes';
import ImovelTipoController from './imoveis-tipos.controller';

import StructureManager from 'isilver-structure-manager';

import APIServ from 'isilver-services';

export default angular.module('app.imoveis-tipos', [uiRouter, StructureManager])
  .config(routing)
  .controller('ImovelTipoController', ImovelTipoController)  
  .run(() => {
    APIServ.salvarEStrutura('imoveis-tipos', require('./imoveis-tipos.tmpl.json'));
  })
  .name;