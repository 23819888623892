var bootbox = require('bootbox');
const $ = require('jquery')

const _mensagemSimples = function (titulo, texto, funcao) {

  bootbox.alert({
    closeButton: false,
    title: titulo,
    message: texto,
    callback: function () {
      if (funcao != undefined) {
        funcao();
      }

    }
  })
}

const _dialogoSimples = function (titulo, texto, funcao, textoSim = 'Ok', textoNao = 'Cancelar') {
  return bootbox.confirm({
    closeButton: false,
    title: titulo,
    message: texto,
    buttons: {
      cancel: {
        label: '<i class="fas fa-times"></i> ' + textoNao
      },
      confirm: {
        label: '<i class="fas fa-check"></i> ' + textoSim
      }
    },
    callback: function (resultado) {
      if (resultado) {
        funcao();
      }
    }
  })
}

const _dialogoComposto = function(titulo, texto, funcaoSim, funcaoNao, textoSim = 'Sim', textoNao = 'Não'){
  return bootbox.confirm({
    closeButton: false,
    title: titulo,
    message: texto,
    buttons: {
      cancel: {
        label: '<i class="fas fa-times"></i> ' + textoNao
      },
      confirm: {
        label: '<i class="fas fa-check"></i> ' + textoSim
      }
    },
    callback: function (resultado) {
      if (resultado) {
        funcaoSim();
      }else{
        funcaoNao();
      }
    }
  })
}

const _abrirTelaAguarde = function (texto) {  
  $('.titulo-modal').html(texto);
  $('.modal-body img').fadeIn('fast');  
  $('#modalSimples').modal('show');
}

const _fecharTelaAguarde = function (tempoEspera = 500) { 
  setTimeout(() => {
    $('.corpo-modal img').fadeOut('fast');
    $('#modalSimples').modal('hide');  
  }, tempoEspera);  
}


module.exports = {
    mensagemSimples: _mensagemSimples,
    dialogoSimples: _dialogoSimples,
    dialogoComposto: _dialogoComposto,
    abrirTelaAguarde: _abrirTelaAguarde,
    fecharTelaAguarde: _fecharTelaAguarde
}