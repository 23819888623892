import APIDialogs from 'isilver-dialogs'

class LoginCtrl {
  constructor($rootScope, configSettings) {
    this.dados = {
      login: 'admin',
      senha: 'admin'
    }

    this.$rootScope = $rootScope;
    this.baseUrl = configSettings.baseUrl;
  }


  logarUsuario() {
    const token = window.localStorage.getItem('token');
    const axios = require('axios');

    let temp = JSON.stringify(this.dados);
    let fd = new FormData();
    fd.append('token', token);
    fd.append('parametros', temp);
    axios.post(this.baseUrl + 'usuarios/logarUsuario/', fd, {
      transformRequest: angular.identity,
      headers: {
        'Content-Type': undefined
      }
    }).then(retorno => {
      if (retorno.data.usuario.chave_usuario >= 0) {
        this.usuarioInvalido = false;

        window.localStorage.setItem('usuario', JSON.stringify(retorno.data.usuario));
        window.localStorage.setItem('token', retorno.data.usuario.sessao);
        window.localStorage.setItem('menus', JSON.stringify(retorno.data.menus));
        this.$rootScope.menus = retorno.data.menus;
        this.$rootScope.logged = true;
        this.$rootScope.$apply();
        window.location.href = './#/';
      }else{
        APIDialogs.mensagemSimples('Atenção', 'Usuário ou Senha Incorretos!');
      }
      //window.location.href = '/';
    })
  }
}

LoginCtrl.$inject = ['$rootScope', 'configSettings'];

export default LoginCtrl;