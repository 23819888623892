routes.$inject = ['$stateProvider'];

export default function routes($stateProvider) {
  $stateProvider
    .state('usuarios', {
      url: '/usuarios',
      template: '<structure-manager></structure-manager>',
      controller: 'UserCtrl',
      controllerAs: 'userCtrl',
      estrutura: require('./users.tmpl.json')
    })
    .state('usuario-alterarSeha', {
      url: '/usuario-alterarSenha',
      template: require('./userChangePassword.html'),
      controller: 'UserCtrl',
      controllerAs: 'UserdCtrl'      
    })
    .state('usuarios-perfis', {
      url: '/usuarios-perfis',
      template: require('./usersProfiles.html'),
      controller: 'UserCtrl',
      controllerAs: 'UserdCtrl'      
    })
    

}