import angular from 'angular';
import $ from 'jquery';
import APIServ from 'isilver-services';
import EGFuncoes from 'isilver-structure-manager/structure-manager.functions'

class SearchHeaderSelectField {
    constructor($scope) {

    }
}
SearchHeaderSelectField.$inject = ['$scope'];

export default angular.module('searchHeaderSelectField', [])
    .directive('searchHeaderSelectField', ['$compile', function ($compile) {
        return {
            restrict: 'E',
            replace: true,
            template: `<select id="campo_consulta" class="form-control"><option value="">Selecione o Campo</option></select>`,
            link: function (scope, elem) {
                var montaCampoValor = function (mascara, valor, indice) {
                    let atributos = [];
                    let classes = [];
                    if (mascara == 'data') {
                        atributos.push('ui-data');
                        atributos.push('placeholder="dd/mm/aaaa"');
                        classes.push('data');
                    } else if (mascara == 'hora') {
                        atributos.push('ui-hora');
                        atributos.push('placeholder="hh:mm"');
                    } else if (mascara == 'telefone') {
                        atributos.push('ui-Telefone');
                    } else if (mascara == 'inteiro') {
                        atributos.push('ui-Inteiro');
                    } else if (mascara == 'decimal2') {
                        atributos.push('ui-Decimal2');
                    } else if (mascara == 'cep') {
                        atributos.push('ui-cep');
                    } else if (mascara == 'cpf-cnpj') {
                        atributos.push('ui-cpf-cnpj')
                    } else if (mascara == 'placa') {
                        atributos.push('ui-placa');
                    }
                    var modeloValor = `filtros[${indice}]['valor']`;
                    var idValor = EGFuncoes.modeloParaId(modeloValor);
                    if (!APIServ.eObjeto(valor)) {
                        var input = `<input type="text" ng-model="${modeloValor}" id="${idValor}" ${atributos.join(' ')} class="${classes.join(' ')}  form-control valorConsulta" ng-if="filtro.tipo != 'intervaloDatas'">
                        <div class="input-group" ng-if="filtro.tipo == 'intervaloDatas'">
                            <span class="input-group-addon">D. F.</span>
                            <input type="text" class="data form-control" ng-model="filtro.df" ui-data placeholder="Data Final">
                        </div>`;
                    } else if (APIServ.eObjeto(valor)) {
                        var input = `
                            <select ng-model="${modeloValor}" ng-options="key as value for (key, value) in filtro.valoresMascara" id="${idValor}" ${atributos.join(' ')} class="${classes.join(' ')} form-control">
                            </select>`;
                    }

                    return input;
                }

               // $parse('camposFiltroPesquisa').assign(scope, scope.camposFiltroPesquisa);
                elem.attr('ng-model', `filtros[${scope.$index}]['campo']`);
                elem.attr('id', `filtros_${scope.$index}_campo`);
                elem.attr('ng-options', 'key as c.texto for (key, c) in camposFiltroPesquisa');
                $compile(elem)(scope);

                elem.bind('click change', function () {
                    if (this.value == '') {
                        return false;
                    }

                    var indice = scope.$index;
                    var campo = scope.filtros[indice]['campo'];

                    //Juntando os valores do campo nos tres locais possiveis, campo, campos filtro pesquisa e campos filtros padrao
                    let dadosCampo = APIServ.buscarValorVariavel(scope.estrutura.campos, campo);
                    let dadosCampoFiltroPesquisa = scope.estrutura.camposFiltroPesquisa != undefined && scope.estrutura.camposFiltroPesquisa[campo] != undefined ? scope.estrutura.camposFiltroPesquisa[campo] : {};
                    let dadosCampoFiltroPadrao = scope.estrutura.filtrosPadrao != undefined && scope.estrutura.filtrosPadrao[campo] != undefined ? scope.estrutura.filtrosPadrao[campo] : {};
                    let dadosFiltro = Object.assign({}, dadosCampoFiltroPadrao, dadosCampo, dadosCampoFiltroPesquisa);

                    scope.filtros[indice]['texto'] = this.options[this.selectedIndex].innerHTML;
                    let tipoFiltro = dadosFiltro.tipo != undefined ? dadosFiltro.tipo : '';
                    scope.filtros[indice]['tipo'] = tipoFiltro;

                    //Pondo este elemente para ver se ele ira fazer o blur ao clicar
                    elem.attr('tipoFiltro', tipoFiltro);
                    $compile(elem)(scope);
                    $('#filtros_' + indice + '_campo').val('string:' + campo);
                    scope.filtros[indice]['campo'] = campo;

                    if (tipoFiltro != 'intervaloDatas') {
                        let cF = dadosFiltro; // scope.estrutura.camposFiltroPesquisa;

                        let campos = scope.estrutura.campos;

                        let mascara = '';
                        if (cF['tipo'] != undefined && cF['tipo'] != 'texto') {
                            mascara = cF['tipo'];
                        } else if (campo != undefined && campos[campo] != undefined && campos[campo]['tipo'] != undefined && campos[campo]['tipo'] != 'texto') {
                            mascara = campos[campo]['tipo'];
                        }

                        var valorMascara = '';
                        if (campo != '' && campo != null) {
                            let keyArray = $(elem).attr('keyArray');
                            valorMascara = cF['valor'] != undefined ? cF['valor'] : '';
                            valorMascara = valorMascara == 'data' ? APIAjuFor.dataAtual() : valorMascara;
                            scope.filtros[keyArray]['valoresMascara'] = valorMascara;
                            let valor = APIServ.eObjeto(valorMascara) ? valorMascara[0] : valorMascara;
                            //cF != undefined && cF[campo]['valor'] != undefined ? cF[campo]['valor'] : '';

                            scope.filtros[keyArray]['valor'] = valor;
                            let operador = cF.operador != undefined ? cF.operador : 'like'; // cF != undefined && cF[campo]['operador'] != undefined ? cF[campo]['operador'] : 'like';
                            scope.filtros[keyArray]['operador'] = operador;
                        }

                        let div = $(elem).parent('div').siblings('div.divValorConsulta');
                        $(div).html('');

                        if (scope.dispositivoMovel) {
                            div.append('<span class="input-group-addon">Valor</span>');
                            div.removeClass('form-group').addClass('input-group');
                        } else {
                            div.append('<label ng-if="key == 0">Valor</label>');
                            div.removeClass('input-group').addClass('form-group');
                        }

                        let inputValor = montaCampoValor(mascara, valorMascara, indice);
                        div.append(inputValor);

                        if (cF['autoCompleta'] != undefined) {
                            var modeloValor = `filtros[${indice}]['valor']`;
                            var idValor = EGFuncoes.modeloParaId(modeloValor);

                            $('#' + idValor).attr('auto-completa', campo);

                        }

                        $compile(div.contents())(scope);

                    } else if (scope.filtros[indice]['tipo'] == 'intervaloDatas') {
                        if (elem.attr('tipoFiltro') != undefined && elem.attr('tipoFiltro') != 'intervaloDatas') {
                            $(this).trigger('blur');
                        }
                    }
                });
            }
        }
    }])
    .name;
