import angular from 'angular';
import $ from 'jquery';
import APIServ from 'isilver-services';
import APIDialogs from 'isilver-dialogs';

export default function Delete(item) {
    let $scope = angular.element($('#structureManager')).scope();

    let parametros = {
        tabela: $scope.estrutura.tabela,
        campo_chave: $scope.estrutura.campo_chave,
        chave: item[$scope.estrutura.campo_chave],
        aoExcluir: $scope.estrutura.aoExcluir != undefined ? $scope.estrutura.aoExcluir : 'E'
    }

    var funcao = function () {
        APIServ.executaFuncaoClasse($scope.baseUrl, 'classeGeral', 'excluir', parametros).then(function (retorno) {
            console.log(retorno);
            if (retorno.data.chave >= 0) {
                let key = $scope.listaConsulta.indexOf(item);
                $scope.listaConsulta.splice(key, 1);
                //APIDialogs.mensagemSimples('Confirmação', $scope.estrutura.nomeUsual + ' Excluído!');
                $scope.$apply();
            }
        })
    }
    APIDialogs.dialogoSimples('Confirmação', 'Excluir ' + $scope.estrutura.nomeUsual + '!?', funcao, 'Sim', 'Não');
}