import $ from 'jquery';
import axios from 'axios';
import APIServ from 'isilver-services';
import APIDialogs from 'isilver-dialogs';
import EGFuncoes from 'isilver-structure-manager/structure-manager.functions';

export default function SaveFunction (dadosTela, nomeForm) {
    let $scope = angular.element($('#structureManager')).scope();
    let estrutura = $scope.estrutura;
    let parametrosUrl = APIServ.parametrosUrl();

    if ($scope.formularioInvalido) {
        return true;
    }
    var indice = dadosTela['indiceVariavel'];
    var dados = APIServ.transporVariavel($scope[estrutura.raizModelo]);
    APIDialogs.abrirTelaAguarde('Salvando');
    
    let fd = $scope.fd;
    //let fd = new FormData();

    let textoSalvou = $scope.estrutura.textoConfirmaSalvamento != undefined ? $scope.estrutura.textoConfirmaSalvamento : 'Dados Inseridos com Sucesso!';

    fd.append('dados', angular.toJson(dadosTela));

    //Definindo as configuracoes
    let configuracoes = {
        tabela: $scope.estrutura.tabela,
        campo_chave: $scope.estrutura.campo_chave,
        tabelasRelacionadas: $scope.estrutura.tabelasRelacionadas,
        camposSeVazios: []
    }

    //Vendo se ha campos de arquivos para anexar
    angular.forEach($scope.estrutura.campos, function (val, key) {
        if (val.tipo == 'arquivo' || val.tipo == 'imagem') {
            configuracoes['arquivosAnexar'] = configuracoes['arquivosAnexar'] != undefined ? configuracoes['arquivosAnexar'] : [];
            let at = val.atributos_arquivo != undefined ? val.atributos_arquivo : [];
            fd.append(key, dadosTela[key]);
            configuracoes['arquivosAnexar'].push({
                campo: key,
                tipo_arquivo: at.tipo_arquivo != undefined ? at.tipo_arquivo : '',
                largura: at.largura != undefined ? at.largura : '600',
                altura: at.altura != undefined ? at.altura : '600',
                destino: at.caminhoBase != undefined ? at.caminhoBase : '',
                salvarEmDiretorio: at.salvarEmDiretorio != undefined ? at.salvarEmDiretorio : true,
                nomeAnexo: at.nomeAnexo != undefined ? at.nomeAnexo : ''

            })
        } else if (val.seVazio != undefined) {
            //Deixar para uma proxima oportunidade
        }
    });

    if ($scope.estrutura.relacionamentosVerificar != undefined) {
        configuracoes['relacionamentosVerificar'] = $scope.estrutura.relacionamentosVerificar;
    }

    fd.append('configuracoes', angular.toJson(configuracoes));
    fd.append('token', $scope.token);
    //APIServ.executaFuncaoClasse('classeGeral', 'manipula', {dados:dadosTela, configuracoes: configuracoes});
    
    axios.post($scope.baseUrl + '/manipulaTabela', fd, {
        transformRequest: angular.identity,
        headers: {
            'Content-Type': undefined
        }
    })
        .then(function (retorno) {
            $scope.fd = new FormData();
            //APIDialogs.fecharTelaAguarde();
            //console.log(retorno);/*
            let cadastroDiretoUrl = parametrosUrl[1] != undefined && parametrosUrl[1] == 'cadastro' ? true : false;
            var funcao = function () {
                if (EGFuncoes.temConsulta($scope.estrutura) && !cadastroDiretoUrl) {
                    $scope.tela = 'consulta';

                    setTimeout(function () {
                        $('#filtrar').trigger('click');
                    }, 100);
                }

                if (($scope.estrutura.recarregarAposSalvar != undefined && $scope.estrutura.recarregarAposSalvar) || cadastroDiretoUrl) {
                    window.location.reload();
                } else {
                    $scope[$scope.estrutura.raizModelo] = EGFuncoes.novaVariavelRaizModelo($scope.estrutura);
                }
                if ($('#popUp').val() == 'true') {
                    window.close();
                }
                if ($scope.estrutura.executarAposSalvar != undefined) {
                    $scope[$scope.estrutura.executarAposSalvar.funcao]();
                }
                                
                $scope.$apply();
            }

            if ($scope.aoSalvar != undefined) {
                $scope.aoSalvar(retorno.data);
            } else if ($scope.estrutura.tipoEstrutura == 'cadastroUnico') {
                APIDialogs.mensagemSimples('Confirmação', textoSalvou);
            } else if ($scope.estrutura.naoMostrarConfirmacaoAoSalvar == undefined || $scope.estrutura.naoMostrarConfirmacaoAoSalvar == false) {
                APIDialogs.mensagemSimples('Confirmação', textoSalvou, funcao);
            } else {
                funcao();
            }
            
          
        })
}