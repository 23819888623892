import angular from 'angular';
import uirouter from 'angular-ui-router';

import routing from './login.routes';
import LoginCtrl from './login.controller';

import './login.css';

export default angular.module('app.login', [uirouter])
  .config(routing)
  .controller('LoginCtrl', LoginCtrl)
  .name;