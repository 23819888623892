import $ from 'jquery';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import '../node_modules/bootstrap/dist/js/bootstrap.min.js';

export default angular.module('app.validaFormulario', [])
  .directive('validaFormulario', function () {
    return {
      require: '^form',
      priority: -1,
      link: function (scope, element, attr, form) {
        const montaMensagem = function (tipo, campo) {
          switch (tipo) {
            case 'igual':
              return 'Valor Diferente de: ' + campo;
              break;
            case 'invalido':
              return 'Valor Invalido!'
              break;
            default:
              return 'Obrigatorio!';
          }
        }

        const manipularSpan = function (elemento, elementoPai, mensagem, erro, obrigatorio) {
          if (erro) {
            //$(elemento).css('background-color', 'red');
            $(elemento).attr('placeholder', 'Campo Obrigaório');
            $(elemento).addClass('erro');

            $(elemento).attr('data-toggle', 'popover');
            if ($(elemento).attr('data-content') == undefined) {
              $(elemento).attr('data-content', mensagem);
            }
            $(elemento).popover({
              'placement': 'bottom'
            }).popover('show');
          } else {
            $(elemento).removeClass('erro');
            $(elemento).removeAttr('data-toggle');
            $(elemento).removeAttr('data-content');
            $(elemento).popover('hide');
          }
          elementoPai.toggleClass('erro', erro);
        }

        element.bind('submit', function () {
          form.valido = true;
          var nomeForm = form.$name;
          angular.forEach(form, function (value, key) {

            //Varrendo os itens de do formulario e vendo se sao objeto se tem ngModel se e obrigatorio e se nao e valido
            var elemento = typeof value === 'object' && value.hasOwnProperty('$modelValue') ? $("[name='" + value.$name + "']") : undefined;

            if (elemento != undefined && (!value.$valid || elemento.attr('igual') != undefined)) {

              var nomeElemento = elemento[0].name;

              var elementoPai = elemento.closest('div.form-group');
              //let mensagem = _montaMensagem();
              let mensagem = montaMensagem();

              let valido = form[nomeElemento].$valid && !elemento.hasClass('ng-invalid');

              if (!valido) {
                form.valido = false;
                manipularSpan(elemento, elementoPai, mensagem, !valido);
                elemento.attr('placeHolder', 'Campo é Obrigatório!');

              } else if (elemento.attr('igual') != undefined) {
                var valorElemento = form[nomeElemento].$viewValue != undefined ? form[nomeElemento].$viewValue : '';
                var valorComparar = form[elemento.attr('igual').split('-')[0]].$viewValue;
                if (valorElemento != valorComparar) {
                  form.valido = false;
                  valido = false;
                  mensagem = montaMensagem('igual', elemento.attr('igual').split('-')[1])
                  manipularSpan(elemento, elementoPai, mensagem, !valido);
                }
              }

              elemento.bind('keyup change click', function () {
                mensagem = '';
                var valorElemento = form[nomeElemento].$viewValue != undefined ? form[nomeElemento].$viewValue : '';
                valido = form[nomeElemento].$valid;
                //Acredito que essa comparacao seja para comparar campso iguais, tipo senhas ou emails
                //Mas nao sei exatamente pois nao comentei quando fiz.
                if (elemento.attr('igual') != undefined) {
                  var valorComparar = form[elemento.attr('igual').split('-')[0]].$viewValue;
                  if (valorElemento != valorComparar) {
                    form.valido = false;
                    valido = false;
                    mensagem = montaMensagem('igual', elemento.attr('igual').split('-')[1])
                  }
                } else if (valorElemento == '') {
                  form.valido = false;
                  mensagem = montaMensagem();
                } else if (!valido) {
                  form.valido = false
                  mensagem = montaMensagem('invalido');
                }

                manipularSpan(elemento, elementoPai, mensagem, !valido);
              });
            }
          });
        });

      }
    }
  }).name;
//*/