import APIDialogs from 'isilver-dialogs';

function HomeController($scope) {

  $scope.mensagem = mensagem => {
    const funcao = () => {
      window.location.reload();
    }
    APIDialogs.abrirTelaAguarde('Teste');
    APIDialogs.fecharTelaAguarde(5000);
    
  }
}

HomeController.$inject = ['$scope'];
export default HomeController;