import angular from 'angular';
import $ from 'jquery';
import jQuery from 'jquery';

//import './lists.list';
import './lists.functions';
import APIServ from '../isilver-services';
import APIDialogs from '../isilver-dialogs';
import EGFuncoes from '../isilver-structure-manager/structure-manager.functions';


var info = {
    id: '',
    idChave: '',
    nomeLista: '',
    valor: '',
    salvarAoSair: ''
};
const defineinfo = function (obj) {
    info.id = $(obj).attr('id');
    info.idChave = $(obj).attr('idChave');
    info.nomeLista = $(obj).attr('nomeLista').toUpperCase();
    info.valor = $(obj).val();
    info.nomeApresenta = APIServ.primeiraMaiusculo(info.nomeLista);
    info.salvarAoSair = $(obj).attr('salvarAoSair') != undefined ? $(obj).attr('salvarAoSair') : "true";

    return info;
}

var verificandoLista = false;

const listDirective = function ($compile, $parse) {
    return {
        restrict: "A",
        link: function (scope, elem, attr) {
            elem.bind('focus', function () {
                if (elem.attr('info') == undefined) {

                    var campo = elem.attr('campo');
                    var nomeBloco = elem.attr('nome-bloco');
                    var dadosBloco = APIServ.buscarValorVariavel(scope.estrutura.campos, nomeBloco);

                    var temBloco = nomeBloco != undefined;

                    if (temBloco) {
                        var indice = scope.$index;
                        var dadosCampo = APIServ.buscarValorVariavel(dadosBloco, campo);
                    } else {
                        var dadosCampo = APIServ.buscarValorVariavel(dadosBloco, campo);
                    }
                    var elementoChave = $(elem).siblings('.chave_lista');

                    if (temBloco) {
                        if (dadosBloco.variavelSuperior != undefined) {
                            var indice = scope.$parent.$index;
                            var indice2 = scope.$index;
                            //Depois tenho que fazer uma comparacao igual fiz em baixo, pois pode nao haver repeticao no bloco e neste caso nao precisaria do indice2
                            var modeloChave = scope.estrutura.raizModelo + '.' + dadosBloco.variavelSuperior + '[' + indice + ']["' + dadosBloco.nomeBloco + '"][' + indice2 + ']["' + dadosCampo.lista.campoChave + '"]';
                        } else {
                            if (dadosBloco.repeticao != undefined) {
                                var modeloChave = scope.estrutura.raizModelo + '.' + dadosBloco.nomeBloco + '[' + indice + ']["' + dadosCampo.lista.campoChave + '"]';
                            } else {
                                modeloChave = scope.estrutura.raizModelo + '.' + dadosBloco.nomeBloco + '["' + dadosCampo.lista.campoChave + '"]';
                            }

                        }
                        var idChave = EGFuncoes.modeloParaId(modeloChave);
                        elementoChave.attr('ng-model', modeloChave);
                        elementoChave.attr('id', idChave);
                        elementoChave.attr('name', modeloChave);
                        $compile(elementoChave)(scope);
                    } else {
                        var modeloChave = scope.estrutura.raizModelo + '.' + dadosCampo.lista.campoChave;
                        var idChave = modeloChave.split('.').join('_');
                        elementoChave.attr('ng-model', modeloChave);
                        elementoChave.attr('id', idChave);
                        elementoChave.attr('name', modeloChave);
                        $compile(elementoChave)(scope);
                    }
                    elem.attr('nomeLista', dadosCampo.lista.nomeLista);
                    elem.attr('idChave', idChave);
                    var info = defineinfo(elem);
                    elem.attr('info', JSON.stringify(info));

                    $compile(elem)(scope);

                    var mostrar = dadosCampo.lista.mostrarNoFoco != undefined && dadosCampo.lista.mostrarNoFoco;

                    $(elem).listas({
                        letra: "mai",
                        mostrarnofoco: mostrar,
                        nomeLista: dadosCampo.lista.nomeLista,
                        mostrarNoFoco: dadosCampo.lista.mostrarNoFoco
                    });
                }               
            });

            elem.bind('blur', function () {
                if (verificandoLista) {
                    return false;
                }

                verificandoLista = true;

                $(this).attr('salvandoLista', true);

                var info = $.parseJSON($(this).attr('info'));

                if (this.value == '') {
                    $('#' + info.id_chave).val(0);
                    angular.element(jQuery($('#' + $(this).attr('id_chave')))).triggerHandler('change');
                    return false;
                }

                this.value = APIServ.maiusculo(this.value);
                //this.value = APIServ.limparAcentos($(this).val(), 'nao');

                info['valor'] = $(this).val();// APIServ.limparAcentos($(this).val());

                if (info.salvarAoSair == "true") {
                    //Vendo se texto digitado est? na tabela listas
                    var funcaoSim = function () {
                        var chave = $.salvarLista(info);
                        $parse(elem.attr('modelo-chave')).assign(scope, chave);
                        scope.$apply();

                        verificandoLista = false;
                    }
                    var funcaoNao = function () {
                        $parse(elem.attr('ng-model')).assign(scope, '');
                        $parse(elem.attr('modelo-chave')).assign(scope, '0');
                        scope.$apply();

                        verificandoLista = false;
                    }
                    if (!$.listaExiste(info)) {
                        //$.modaldialogo('Confirmação', info.nomeApresenta + ' nao está na lista salvá-lo?', funcaoSim, funcaoNao);
                        APIDialogs.dialogoComposto('Confirmação', info.nomeApresenta + ' não está na Lista. Salvá-lo?', funcaoSim, funcaoNao);
                    }
                }

            });

            ;; (function ($) {
                jQuery.fn.listas = function (opcoes) {
                    if (!$(this).attr('temFuncaoLista')) {
                        var padroes = {
                            letra: 'mai',
                            mostrarNoFoco: false,
                            salvarAoSair: true
                        };

                        var confi = $.extend({}, padroes, opcoes);
                        if (!$(this).attr('info')) {
                            $.defineinfo(this);
                            //$(this).attr('info', JSON.stringify(info));
                        }

                        var info = $(this).attr('info');
                        var baseUrl = APIServ.descriptgrafa(APIServ.buscarDadosLocais('baseUrl'));
                        var token = APIServ.buscarDadosLocais('token');

                        $(this).autocomplete({
                            minLength: 0,
                            source: function (request, response) {
                                console.log('ini');

                                $.ajax({
                                    url: baseUrl + 'autoCompletaLista/',
                                    dataType: "json",
                                    data: {
                                        parametros: {
                                            d: request.term,
                                            n: opcoes.nomeLista,
                                            token: token
                                        }
                                    },
                                    success: function (data) {
                                        console.log(data);

                                        response($.map(data, function (item) {
                                            return {
                                                chave: item.chave_lista,
                                                value: item.value
                                            };
                                        }));
                                    },
                                    error: function (xhr, ajaxOptions, thrownError) {
                                        alert(xhr.status);
                                        alert(thrownError);
                                    }
                                });
                            },
                            autoFocus: true,
                            minLength: 0,
                            select: function (event, ui) {
                                $parse(elem.attr('ng-model')).assign(scope, ui.item.value);
                                $parse(elem.attr('modelo-chave')).assign(scope, ui.item.chave);
                                scope.$apply();
                            }
                        });
                    }
                }
            })(jQuery);
        }
    }
}

listDirective.$inject = ['$compile', '$parse'];
export default listDirective;