import angular from 'angular';
import $ from 'jquery';

import APIServ from 'isilver-services';
import EGFuncoes from './structure-manager.functions';
import APIAjuFor from 'isilver-helpers-format';

export default angular.module('structureManagerListItemDetails', [])
.directive('structureManagerListItemDetails', ['$rootScope', '$compile', function($rootScope, $compile) {
    return {
        restrict: 'E',
        replace: true,
        template: '',
        link: function(scope, elem) {            
            var indice = $(elem).closest('.itemConsulta').attr('indice');

            //Fazendo rotina para exibir detalhes quando for somente consulta
            //20/11/2017 Acrescentei a juncao dos campos com os camposDetalhes quando existirem
            var campos = scope.estrutura.camposDetalhes != undefined ? angular.merge(scope.estrutura.campos, scope.estrutura.camposDetalhes) : scope.estrutura.campos;

            var html = '';

            angular.forEach(campos, function(val, key) {
                if (key.substr(0, 5) == 'bloco') {
                    html += `<bloco-html-detalhe nome-bloco="${key}" indice="${indice}"></bloco-html-detalhe>`; //_montaBlocoHtmlDetalhes(key, val);
                } else if (val == 'diretiva') {
                    let nomeDiretiva = APIAjuFor.variavelParaDiretiva(key);
                    html += `<${nomeDiretiva} indice="${indice}"></${nomeDiretiva}>`;

                } else if ((val.tipo == undefined || val.tipo != 'oculto') && key.substr(0, 5) != 'botao' && val.tipo != 'senha') {
                    var camposNaoMostrar = Object.keys(scope.estrutura.listaConsulta);
                    var mostrar = !APIServ.valorExisteEmVariavel(camposNaoMostrar, key);
                    if (mostrar) {
                        html += `<html-detalhe campo="${key}" indice="${indice}"></html-detalhe>`
                    }
                }
            });
            elem.addClass('row top10')
            elem.html(html);
            $compile(elem.contents())(scope);
        }
    }
}])
.directive('htmlDetalhe', ['$compile', function($compile) {
    return {
        restrict: 'E',
        replace: true,
        link: function(scope, elem) {
            let campo = elem.attr('campo');
            let camposOcultarDetalhes = scope.estrutura.camposOcultarDetalhes != undefined ? scope.estrutura.camposOcultarDetalhes : [];
            var mostrar = !APIServ.valorExisteEmVariavel(camposOcultarDetalhes, campo);
            if (mostrar) {
                var val = APIServ.buscarValorVariavel(scope.estrutura.campos, campo);
                //console.log(val);

                var label = val.texto != undefined && val.tipo != 'oculto' ? val.texto : '';
                var atributosDiv = val.atributos_div != undefined ? EGFuncoes.montarAtributos(val.atributos_div) : [];
                var tamanho = EGFuncoes.montarTamanhos(val);
                var classesDiv = val.classes_div != undefined ? val.classes_div.split(' ') : [];
                classesDiv = classesDiv.concat(tamanho);
                var valor = APIServ.buscarValorVariavel(scope.listaConsulta[elem.attr('indice')]['detalhes'], elem.attr('campo'));
                var comp = ''; //Ver depois pois pode ser autoCompleta e ter complemento valor


                elem.addClass(classesDiv.join(' '));
                var html = `
                <div class="campoDetalheConsulta"  ${atributosDiv.join(' ')}>${label} : ${valor} ${comp}</div>`;
                elem.html(html);
                $compile(elem.contents())(scope);
            }
        }
    }
}])
.name;