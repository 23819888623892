import angular from 'angular';
import axios from 'axios';
import $ from 'jquery';
import APIServ from 'isilver-services';

import APIDialogs from 'isilver-dialogs';

export default function FilterFunction(pagina = 1) {
    
    let $scope = angular.element($('#structureManager')).scope();
    $scope.tela = 'consulta';

    let estrutura = $scope.estrutura;
    let acao = $scope.acao;

    if ($scope.antesDeFiltrar != undefined) {
        $scope.antesDeFiltrar();
    }

    if ($scope.estrutura.filtroObrigatorio != undefined && $scope.estrutura.filtroObrigatorio) {
        let filtroVer = $scope.filtros[0];
        let temValor = filtroVer.valor != '' || (filtroVer.di != undefined && filtroVer.di != '') || (filtroVer.df != undefined && filtroVer.df != '');
        if (filtroVer.campo == '' || !temValor) {
            APIServ.mensagemSimples('Defina ao Menos um Filtro');
            return false;
        }
    }

    //Esta funcao foi criada, pois pode haver casos de ter intervalo de datas no filtro, ai tenho que divilo em dois.

    $scope.filtroEnviar = $scope.converterFiltroParaEnvio();

    APIDialogs.abrirTelaAguarde();
    let resumo = [];
    if (estrutura.resumoConsulta != undefined) {
        angular.forEach(estrutura.resumoConsulta, function (val, key) {
            resumo.push({
                campo: key,
                operacao: val.operacao
            });
        })
    }
    

    var campos = [];
    angular.forEach($scope.estrutura.listaConsulta, function (itemLC, keyLC) {
        if (angular.isObject(itemLC)) {
            campos.push(keyLC)
        } else {
            campos.push(itemLC)
        }
    });

    let ordemFiltro = $('#ordemFiltro').length > 0 ? $('#ordemFiltro').val().split(':')[1] : '';
    let sentidoFiltro = $('#sentidoFiltro').val();

    let filtros = {
        tabela: $scope.estrutura.tabela,
        tabelaConsulta: $scope.estrutura.tabelaConsulta != undefined ? $scope.estrutura.tabelaConsulta : $scope.estrutura.tabela,
        tela: acao,
        campo_chave: $scope.estrutura.campo_chave,
        pagina: pagina,
        campos: campos, // Object.keys($scope.estrutura.listaConsulta),
        filtros: $scope.filtroEnviar,
        ordemFiltro: ordemFiltro != '' ? ordemFiltro + ' ' + sentidoFiltro : '',
        itensPagina: '',
        resumoConsulta: resumo,
        dispositivoMovel: $scope.dispositivoMovel,
        tabelasRelacionadas: $scope.estrutura.tabelasRelacionadas,
        todosItensSelecionados: $scope.todosItensSelecionados != undefined ? $scope.todosItensSelecionados : false,
        tirarCampoChaveConsulta: $scope.estrutura.tirarCampoChaveConsulta
    }

    //Fazendo comparacao se e pagina sem consulta, mostrando resultados direto ao abrir
    if (pagina > 0) {
        filtros.ordemFiltro = filtros.ordemFiltro == '' && $('#ordemFiltro').length > 0 ? $('#ordemFiltro').val().split(':')[1] : filtros.ordemFiltro;
        filtros.itensPagina = $('#itensPagina') != undefined ? $('#itensPagina').val() : 0;
    }
    let fd = new FormData();
    fd.append('parametros', angular.toJson(filtros));
        

    axios.post($scope.baseUrl + '/consulta', fd, {
        transformRequest: angular.identity,
        headers: {
            'Content-Type': undefined
        }
    }).then(function (data) {

        //console.log(data);/*
        data = data.data;
        APIDialogs.fecharTelaAguarde();
        $scope.todosItensSelecionados = false;
        $('#todosItensSelecionados').attr('checked', false);

        //Salvando o filtro, pois caso atualize a página mantem o filtro
        //APIServ.salvarDadosLocais(nomeFiltroLocal, $scope.filtros);
        //console.log(APIServ.buscarDadosLocais('filtro' + APIServ.parametrosUrl[0]));

        if (pagina > 0) {
            $scope.limitePaginaAtiva = data.paginacao.limitePaginaAtiva;
            $scope.qtdItensRetornados = data.paginacao.qtdItensRetornados;
            $scope.qtdPaginas = data.paginacao.qtdPaginas;
            $scope.paginasMostrar = data.paginacao.paginasMostrar;
            $scope.pagina = data.paginacao.pagina;
            $scope.primeiroNumero = data.paginacao.primeiroNumero;
            $scope.ultimoNumero = data.paginacao.ultimoNumero;
            $scope.numerosPaginas = [];
            $scope.resumoConsulta = data.resumoConsulta;
            for (var c = data.paginacao.primeiraPagina; c <= data.paginacao.ultimaPagina; c++) {
                $scope.numerosPaginas.push(c);
            }
        }
        $scope.listaConsulta = data.lista;

        if ($scope.aposFiltrar != undefined) {
            $scope.aposFiltrar();
        }       
        
        $scope.$apply();
        //*/
    }).catch(function (data, status, headers, config) {
        console.log(headers);
        APIDialogs.fecharTelaAguarde()
    });

}