import angular from 'angular';
import jQuery from 'jquery';
import $ from 'jquery';
import '../node_modules/jquery-ui/ui/widgets/autocomplete';

import APIServ from '../isilver-services';
import EGFuncoes from '../isilver-structure-manager/structure-manager.functions';

export default angular.module('autocomplete', [])
.directive('autoCompleta', ['$compile', '$parse', function($compile, $parse) {
    return {
        restrict: "A",
        link: function(scope, elem, attr) {
            let baseUrl = APIServ.descriptgrafa(APIServ.buscarDadosLocais('baseUrl'));
            
            let e = scope.estrutura;

            let campo = elem.attr('auto-completa');

            let temCampoFiltros = e.camposFiltroPesquisa != undefined && e.camposFiltroPesquisa[campo] != undefined;
            let temCampoCampos = APIServ.buscarValorVariavel(scope.estrutura.campos, campo);

            let buscarCampoCompleto = campo => {
                //Vendo de onde buscar o campo completo
                let campoFiltro = temCampoFiltros && (scope.tela == undefined || scope.tela == 'consulta') ? scope.estrutura.camposFiltroPesquisa[campo] : [];
                let campoCampos = temCampoCampos ? APIServ.buscarValorVariavel(scope.estrutura.campos, campo) : [];
                return Object.assign(campoFiltro, campoCampos);
            }

            let modeloChave;
            let objValor2 = '';


            let campoCompleto = buscarCampoCompleto(campo);

            let dAC = campoCompleto.autoCompleta;

            dAC.nomeBloco = attr.nomeBloco != undefined ? attr.nomeBloco : '';
            let temBloco = attr.nomeBloco != undefined;



            let dC;
            let dB = APIServ.buscarValorVariavel(e.campos, dAC.nomeBloco);

            let limparElemento = (dadosAC, modeloValor, modeloChave, modeloValor2, modeloValor3) => {

                $parse(modeloValor).assign(scope, undefined);
                if (modeloChave) {
                    $parse(modeloChave).assign(scope, 0);
                }
                if (modeloValor2) {
                    $parse(modeloValor2).assign(scope, undefined);
                }
                if (modeloValor3) {
                    $parse(modeloValor3).assign(scope, undefined);
                }
            }

            elem.bind('keyup', () => {
                if (!elem.hasClass('valorConsulta')) {

                    let elemAC = $(event.target);
                    elemAC.val(elemAC.val().toUpperCase());

                    let indice = $(event.target).attr('indice');
                    let modeloChaveAC = elemAC.attr('modelo-chave').replace('$index', indice);

                    let oldChave = eval('scope.' + modeloChaveAC) != undefined ? eval('scope.' + modeloChaveAC) : 0;

                    let parametros = {
                        tabela: dAC.tabela,
                        campo: campo,
                        valor: $(event.target).val()
                    }
                    APIServ.executaFuncaoClasse('classeGeral', 'objetoexistesimples', parametros).then(retorno => {
                        if (retorno.data.existe) {
                            $parse(modeloChaveAC).assign(scope, retorno.data.valorinformar);
                        } else {
                            $parse(modeloChaveAC).assign(scope, oldChave);
                        }
                    })
                }
            });

            elem.bind("change", () => {
                let campo = elem.attr('auto-completa');
                let dadosAC = buscarCampoCompleto(campo)['autoCompleta'];

                if (dadosAC.camposLimparAoMudar != undefined) {
                    for (let keyCampoApagar in dadosAC.camposLimparAoMudar) {
                        let modeloApagar = elem.attr('ng-model').replace(elem.attr("campo"), dadosAC.camposLimparAoMudar[keyCampoApagar])
                        $parse(modeloApagar).assign(scope, undefined);
                    }
                }
            })

            elem.bind("blur", function() {
                //Se for valor de consulta, não precisa verificar se existe na base
                if (elem.hasClass('valorConsulta')) {
                    return false;
                }

                let indice = 0;
                if (temBloco) {
                    indice = $(event.target).attr('indice');
                }

                let elemAC = $(event.target);

                //Se estiver vazio, e tiver o modelo da chave, seto 0
                let modeloChaveAC = elemAC.attr('modelo-chave').replace('$index', indice);
                let modeloAC = elemAC.attr('ng-model').replace('$index', indice);
                let modeloValor2 = elemAC.attr('modelo-valor2') != undefined ? elemAC.attr('modelo-valor2').replace('$index', indice) : undefined;

                let valorElemento = eval('scope.' + modeloAC);

                let campoCompleto = buscarCampoCompleto(campo);
                let aC = campoCompleto['autoCompleta'];

                if (valorElemento == '' || valorElemento == undefined) {
                    limparElemento(aC, modeloAC, modeloChaveAC, modeloValor2)
                } else if (aC.permitirValorInvalido == undefined || aC.permitirValorInvalido == false) {
                    let parametrosBuscaChave = {
                        tabela: aC.tabela,
                        campos: [aC.campoValor],
                        valores: [eval('scope.' + modeloAC).split('--')[0]]
                    }
                    let fd = new FormData();
                    fd.append('parametros', JSON.stringify(parametrosBuscaChave));
                    fd.append('token', APIServ.buscarDadosLocais('token'));

                    APIServ.executaFuncaoClasse(baseUrl, 'classeGeral', 'buscarChavePorCampos', fd, 'post').then(retorno => {
                        console.log(retorno.data);
                        
                        if (retorno.data == '' || (eval('scope.' + modeloChaveAC) == 0 || !eval('scope.' + modeloChaveAC))) {
                            limparElemento(aC, modeloAC, modeloChaveAC, modeloValor2);
                        }
                    })
                }
            })

            elem.bind("focus", function() {
                $(this).attr('autocomplete', 'off');
                if (temBloco) {
                    var indice = scope.$index;
                }

                //Vendo de onde buscar o campo completo
                let campoCompleto = buscarCampoCompleto(campo);

                let aC = campoCompleto.autoCompleta;

                var campoChave = aC.objChave != undefined ? aC.objChave : aC.campoChave != undefined ? aC.campoChave : e.campo_chave;

                if (temBloco) {
                    if (dB.variavelSuperior != undefined) {

                        var indice = scope.$parent.$index;
                        var indice2 = scope.$index;
                        //Depois tenho que fazer uma comparacao igual fiz em baixo, pois pode nao haver repeticao no bloco e neste caso nao precisaria do indice2
                        modeloChave = e.raizModelo + '.' + dB.variavelSuperior + '[' + indice + ']["' + dB.nomeBloco + '"][' + indice2 + ']["' + campoChave + '"]';

                        objValor2 = aC.objValor2 != undefined ?
                            e.raizModelo + '.' + dB.variavelSuperior + '[' + indice + ']["' + dB.nomeBloco + '"][' + indice2 + ']["' + aC.objValor2 + '"]' : '';
                    } else {
                        if (dB.repeticao != undefined) {
                            modeloChave = e.raizModelo + '.' + dB.nomeBloco + '[' + indice + ']["' + campoChave + '"]';
                        } else {
                            modeloChave = e.raizModelo + '.' + dB.nomeBloco + '["' + campoChave + '"]';
                            objValor2 = aC.objValor2 != undefined ? e.raizModelo + '.' + dB.nomeBloco + '[' + indice + ']["' + aC.objValor2 + '"]' : '';
                        }

                    }
                } else {
                    objValor2 = aC.objValor2 != undefined ? e.raizModelo + '_' + aC.objValor2 : '';

                    if (objValor2 != '') {
                        elem.attr('modelo-valor2', $('#' + objValor2).attr('ng-model'));
                    }

                    //Modificado em 07-01-2019 devido a necessidade de por campo autoCompleta em diretiva
                    if (aC.modeloChave != undefined) {
                        modeloChave = e.raizModelo + '.' + aC.modeloChave;
                    } else if (aC.objChave == undefined) {
                        modeloChave = e.raizModelo + '.' + campoChave;
                    } else {
                        modeloChave = elem.attr('modelo-chave');
                    }
                }

                //Limpando o elemento no foco
                if (aC.limparNoFoco != undefined && (aC.limparNoFoco == 'true' || aC.limparNoFoco == true)) {
                    $parse(elem.attr('ng-model')).assign(scope, '');
                    $parse(modeloChave).assign(scope, '');
                    if (objValor2 != undefined && objValor2 != '') {
                        let elemento2 = angular.element('#' + EGFuncoes.modeloParaId(objValor2));
                        elemento2.val('');
                        $parse(elemento2.attr('ng-model')).assign(scope, '');
                        let chaveElemento2 = elemento2.siblings('.chave_auto_completa');
                        chaveElemento2.val('');
                        $parse(chaveElemento2.attr('ng-model')).assign(scope, '');
                    }

                    if (aC.aoLimparNoFoco != undefined) {
                        eval('scope. ' + aC.aoLimparNoFoco);
                    }
                }


                let chave2;
                if (aC.campoChave2 != undefined) {
                    //Vendo se tem a chave2 no mesmo nivel do auto completaca
                    let chave2MesmoNivel = eval('scope.' + EGFuncoes.trocarCampoModelo(event, modeloChave, campoChave, aC.campoChave2));
                    let chave2Raiz = eval('scope.' + e.raizModelo + '.' + aC.campoChave2)
                    chave2 = aC.chave2 != undefined ? aC.chave2 : chave2MesmoNivel ? chave2MesmoNivel : chave2Raiz ? chave2Raiz : undefined;
                }

                let chave3;
                if (aC.campoChave3 != undefined) {
                    chave3 = aC.chave3 != undefined ? aC.chave3 : eval('scope.' + EGFuncoes.trocarCampoModelo(event, modeloChave, campoChave, aC.campoChave3));
                }
                
                var autoCompleta = {
                    tabela: aC.tabela,
                    campo_chave: aC.campoChave,
                    campo_valor: aC.campoValor,
                    complemento_valor: aC.complementoValor,
                    campo_valor2: aC.campoValor2 != undefined ? aC.campoValor2 : '',
                    obj_valor2: EGFuncoes.modeloParaId(objValor2),
                    mascara_valor2: aC.mascaraValor2 != undefined ? aC.mascaraValor2 : '', //Remover depois
                    minimo: aC.minimo != undefined ? aC.minimo : 0,
                    mostrarnofoco: aC.mostrarNoFoco != undefined ? aC.mmostrarNoFoco : 'S',
                    campo_chave2: aC.campoChave2,
                    chave2: chave2,
                    campo_chave3: aC.campoChave3,
                    chave3: chave3,
                    ordenar: aC.ordenar != undefined ? aC.ordenar : 'S',
                    obj_chave: aC.objChave != undefined ? aC.objChave : $(elem).siblings('.chave_auto_completa').attr('id'),
                    apagarsevazio: aC.apagarSeVazio ? aC.apagarSeVazio : 'S',
                    repetirvalores: aC.repetirValores != undefined ? aC.repetirValores : 'N',
                    aoSelecionar: aC.aoSelecionar,
                    modelo: elem.attr('ng-model'),
                    modeloChave: modeloChave,
                    verificarEmpresaUsuario: aC.verificarEmpresaUsuario ? aC.verificarEmpresaUsuario : false,
                    autoSelecionar: aC.autoSelecionar != undefined ? aC.autoSelecionar : true
                }

                let idElemento = '#' + attr.id; // EGFuncoes.modeloParaId(elem.attr('ng-model'));

                //if ( ! $(idElemento).attr('autoCompletaInserido')) {
                //console.log('in');
                //$(idElemento).attr('autoCompletaInserido', true);
                $(idElemento).autocompletaAngular(autoCompleta);
                //}
                setTimeout(function() {
                    $(idElemento).autocomplete("search", $(idElemento).val());
                }, 100);

            });

            (function($) {
                jQuery.fn.autocompletaAngular = function(parametrosBusca) {
                    if (parametrosBusca.campo_chave2 != undefined && parametrosBusca.chave2 <= 0) {
                        return false;
                    }

                    $(this).autocomplete({
                        minLength: parametrosBusca.minimo,
                        autoFocus: parametrosBusca.autoSelecionar,
                        source: function(request, response) {
                            //console.log(request);
                            $.ajax({
                                url: baseUrl + "autoCompleta/",
                                type: 'GET',
                                async: false,
                                dataType: "json",
                                data: {
                                    term: request.term,
                                    funcao_executar: 'completacampo',
                                    parametros: parametrosBusca
                                },
                                success: function(data) {
                                    //console.log(data); /*
                                    var maximo_tamanho_valor = 0;
                                    var maximo_tamanho_complemento = 0;

                                    $.map(data, function(item) {
                                        maximo_tamanho_valor = item.valor.length > maximo_tamanho_valor ? item.valor.length : maximo_tamanho_valor;
                                        maximo_tamanho_complemento = item.complemento_valor.length > maximo_tamanho_complemento ? item.complemento_valor.length : maximo_tamanho_complemento;
                                    });

                                    $.retornavalor = function(valor, complemento) {
                                        var tamanho = valor.length + complemento.length + 5;
                                        var tamanho_espaco = maior_tamanho - tamanho;
                                        var espaco = " ";
                                        for (let i = 0; i <= tamanho_espaco; i++) {
                                            espaco += "";
                                        }
                                        var retorno = valor;
                                        retorno += complemento != '' ? ' -- ' + espaco + complemento : '';
                                        return retorno.trim();
                                    };

                                    var maior_tamanho = maximo_tamanho_valor + maximo_tamanho_complemento + 5;
                                    response($.map(data, function(item) {
                                        let valor = $.retornavalor(item.valor, item.complemento_valor);

                                        return {
                                            label: valor,
                                            id: item.chave,
                                            label2: item.valor2
                                        };
                                    }));
                                    //*/
                                },
                                error: function(a, b, c) {
                                    alert(c);
                                }
                            });
                        },
                        select: function(event, ui) {
                            let elemAC = $(event.target);
                            let indice = $(event.target).attr('indice');

                            let eConsulta = elem.hasClass('valorConsulta');

                            let modeloAC = eConsulta ? elemAC.attr('ng-model') : EGFuncoes.indexPorNumero(event, elemAC.attr('ng-model'));// elemAC.attr('ng-model').replace('$index', indice);
                            $parse(modeloAC).assign(scope, ui.item.label);

                            let modeloValor2;
                            let modeloChaveAC;

                            if (! eConsulta ) {
                                modeloChaveAC = EGFuncoes.indexPorNumero(event, elemAC.attr('modelo-chave'));
                                modeloValor2 = elemAC.attr('modelo-valor2');
                                $parse(modeloChaveAC).assign(scope, ui.item.id);
                                if (modeloValor2 != undefined) {
                                    //Neste caso o campo valor2 sera inserido em um segundo elemento
                                    modeloValor2 = modeloValor2.replace('$index', indice);
                                    $parse(modeloValor2).assign(scope, ui.item.label2);
                                }
                            }


                            if (parametrosBusca.aoSelecionar != undefined) {
                                eval('scope. ' + parametrosBusca.aoSelecionar);
                            }

                            //Limpando a mensagem de erro, caso seja obrigatorio
                            var elementoPai = $(event.target).closest('div.form-group');
                            elemAC.removeClass('erro')
                            elementoPai.removeClass('erro');
                            elemAC.popover('hide');

                            if (modeloValor2 != undefined) {
                                let elementoValor2 = $('#' + EGFuncoes.modeloParaId(modeloValor2));
                                let elementoPaiValor2 = elementoValor2.closest('div.form-group');
                                elementoValor2.removeClass('erro');
                                elementoPaiValor2.removeClass('erro');
                            }

                            scope.$apply();
                        }
                    });
                };
            })(jQuery);
        }
    }
}])
.name;