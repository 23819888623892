import angular from 'angular';
import MenuPainelCtrl from './menuPainel.controller';

import './menuPainel.css';


function menuPainel() {
  return {
    restrict: 'E',
    template: require('./menuPainel.html'),
    controller: MenuPainelCtrl,
    controllerAs: 'menuCtrl'  
  }
}

export default angular.module('directives.menuPainel', [])
  .directive('menuPainel', menuPainel)
  .name;