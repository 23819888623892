const angular = require('angular');
const APIServ = require('isilver-services');
const EGFuncoes = require('./structure-manager.functions');
const $ = require('jquery');

import FilterFunction from './functions/filter';
import SaveFunction from './functions/save';
import DeleteFunction from './functions/delete';

function StructureManagerController($rootScope, $scope, $element, $compile, $http, operadoresConsulta, configSettings) {

    var $rS = $rootScope;

    $scope.baseUrl = configSettings.baseUrl;
    APIServ.salvarDadosLocais('baseUrl', APIServ.criptografa(configSettings.baseUrl));
    $scope.token = window.localStorage.getItem('token');

    $scope.popUp = document.getElementById('popUp') != undefined && document.getElementById('popUp').value;

    $scope.larguraTela = window.screen.availWidth;

    $scope.alturaTela = window.screen.availHeight;
    $scope.dispositivoMovel = $scope.larguraTela <= 900;

    let html = '';
    $scope.fd = new FormData();


    let retorno = $scope.$resolve.$state$.estrutura;
    //Fazendo a validacao dos poderes do usuario
    var menuPainel = APIServ.buscarDadosLocais('menus');

    let parametrosUrl = APIServ.parametrosUrl();

    let parametrosLocal = [];

    if (!$scope.popUp) {
        APIServ.apagarDadosLocais('parametrosUrl');
    }

    if (parametrosUrl.length == 0) {
        parametrosLocal = APIServ.buscarDadosLocais('parametrosUrl');
    }
    //APIServ.apagarDadosLocais('parametrosUrl');

    let raizModelo = retorno.raizModelo;

    let pagina = parametrosUrl[0] != undefined ? parametrosUrl[0] : parametrosLocal['pagina'];
    $scope.pagina = pagina;
    let acao = parametrosUrl[1] != undefined ? parametrosUrl[1] : parametrosLocal['acao'];
    let subacao = parametrosUrl[2] != undefined ? parametrosUrl[2] : parametrosLocal['subAcao'];

    let nomeFiltroLocal = 'filtro' + acao;
    let nomeFiltroTemp = 'filtroTemp_' + acao;
    let filtroTemp = APIServ.buscarDadosLocais(nomeFiltroTemp);
    let filtroLocal = APIServ.buscarDadosLocais(nomeFiltroLocal);

    if ($rS[pagina] == undefined) {
        $rS[pagina] = {};
        $rS[pagina]['acoes'] = menuPainel.acoes != undefined && menuPainel.acoes[pagina] != undefined ? menuPainel.acoes[pagina] : [];
    }

    $scope.estrutura = retorno;
    $rootScope.estrutura = retorno;

    retorno['tipoEstrutura'] = retorno.tipoEstrutura != undefined ? retorno.tipoEstrutura : 'padrao';

    var _montarCamposFiltroConsulta = function (campos, retornoEnt) {

        //Despois tenho que por opcoes de pesquisar por tabelas relacionadas
        var retornoMCFC = retornoEnt != undefined ? retornoEnt : [];
        angular.forEach(campos, function (val, campo) {
            if (EGFuncoes.eBloco(campo) && val.nome == undefined) {
                retornoMCFC = _montarCamposFiltroConsulta(val.campos, retornoMCFC);
            } else if (val.tipo != 'oculto' && val.tipo != 'area-texto' && campo.substr(0, 5) != 'botao' && typeof (val) === "object" && val.tipo != 'diretiva') {
                let ocultarCampoFiltro = $scope.estrutura.camposOcultarFiltroPesquisa != undefined && APIServ.valorExisteEmVariavel($scope.estrutura.camposOcultarFiltroPesquisa, campo);

                if (!ocultarCampoFiltro) {
                    retornoMCFC[campo] = val;
                }
            }
        });
        var filtrosPersonalizados = $scope.estrutura.camposFiltroPesquisa != undefined ? $scope.estrutura.camposFiltroPesquisa : {};
        //console.log(_montarCamposFiltroConsulta(scope.estrutura.campos));
        var retornoMCFC = Object.assign({}, filtrosPersonalizados, retornoMCFC);
        //console.log(retornoMCFC);
        return retornoMCFC;
    }

    $scope.camposFiltroPesquisa = _montarCamposFiltroConsulta($scope.estrutura.campos);

    $scope.campo_chave = retorno.campo_chave;
    var estrutura = retorno;
    $scope.operadoresConsulta = operadoresConsulta;
    $scope.filtros = [];
    $scope.ordemFiltro = '';
    //$scope.itensPagina = "25";
    $scope.opcaoSelecionarTodosItensConsulta = $scope.estrutura.opcaoSelecionarTodosItensConsulta != undefined && $scope.estrutura.opcaoSelecionarTodosItensConsulta;
    $scope.ocultarItensPagina = $scope.estrutura.itensPagina != undefined && $scope.estrutura.itensPagina == -1;
    $scope.itensPagina = $scope.estrutura.itensPagina != undefined ? $scope.estrutura.itensPagina : "50";

    //Vendo se ha a subAcao se sim ponho ela como tela, mais usado no caso de popup
    $scope.tela = $scope.tela != undefined ? $scope.tela : acao != '' ? acao : 'consulta';

    $scope[retorno.raizModelo] = {};

    //$scope.abrirPopUp = OpenPopUp;

    $scope.abrirVisualizacao = (arquivo, largura, altura) => {
        console.log(arquivo);
        var retorno = [];
        var temp = window.location.href;
        let raiz = temp.split('?')[0];
        console.log(raiz + arquivo);
        window.open(raiz + arquivo, 'popup', 'width=' + largura + ',height=' + largura);
    }

    $scope.alterarFiltroResultado = function (filtro) {
        $rS.filtroResultado = filtro;
    }


    $scope[estrutura.raizModelo] = EGFuncoes.novaVariavelRaizModelo($scope.estrutura);

    $scope.adicionarItemRepeticao = function (nomeBloco, obj, valor) {
        var objEnviar = obj != undefined ? obj : this;
        return EGFuncoes.adicionarItemRepeticao($scope, nomeBloco, objEnviar, valor);
    }

    $scope.adicionarTodosItensRepeticao = function (nomeBloco) {
        var dadosBloco = APIServ.buscarValorVariavel($scope.estrutura.campos, nomeBloco);
        let autoCompleta = APIServ.buscarValorVariavel(dadosBloco, 'autoCompleta');
        let parametros = {
            tabela: autoCompleta.tabela
        }
        //console.log(autoCompleta);
        //console.log($scope[$scope.estrutura.raizModelo]);
    }

    $scope.removerItemRepeticao = function (nomeBloco) {
        return EGFuncoes.removerItemRepeticao($scope, nomeBloco);
    }

    $scope.trocarPosicao = (idElemento) => {
        let objeto = $('#' + idElemento);
        let posicaoAtual = Number(objeto.attr('indice')) + 1;
        let posicaoNova = objeto.val().split(':')[1];

        let indiceAtual = objeto.attr('indice');
        let indiceNovo = parseInt(posicaoNova) - 1;

        let nomeVariavelRepeticao = objeto.attr('item-repetir');
        let variavelRepeticao = eval('$scope.' + objeto.attr('item-repetir'));

        let valorAtual = variavelRepeticao[indiceAtual];
        valorAtual['posicao'] = parseInt(posicaoNova);
        let valorNovo = variavelRepeticao[indiceNovo];
        valorNovo['posicao'] = parseInt(posicaoAtual);
        variavelRepeticao[indiceAtual] = valorNovo;
        variavelRepeticao[indiceNovo] = valorAtual;
    }

    //$scope.camposFiltroPesquisa = EGFuncoes.montarCamposFiltroConsulta(retorno.campos);

    $scope.mudaTela = function (tela) {
        if ($('#popUp').val() == 'true') {
            window.close();
        }
        if (tela == 'cadastro') {
            if ($scope.aoCarregar != undefined) {
                $scope.aoCarregar();
            }
            $scope[estrutura.raizModelo] = EGFuncoes.novaVariavelRaizModelo($scope.estrutura);
        }
        $scope.tela = tela;
    }

    if ($scope.tela == undefined && retorno.tipoEstrutura == 'cadastroDireto' || retorno.tipoEstrutura == 'cadastroUnico') {
        $scope.mudaTela('cadastro');
    } else if ($scope.tela == undefined && retorno.tipoEstrutura == 'consulta') {
        $scope.mudaTela('consulta');
    }

    if (EGFuncoes.temConsulta(retorno)) {
        $scope.adicionarFiltro = function () {
            $scope.filtros.push({
                'texto': '',
                'campo': null,
                'operador': 'like',
                'valor': ''
            });
        }

        $scope.adicionarFiltro();

        $scope.ordemFiltro = $scope.estrutura.campoOrdemPadraoFiltro != undefined ? $scope.estrutura.campoOrdemPadraoFiltro : '';
        $scope.sentidoFiltro = $scope.estrutura.sentidoOrdemPadraoFiltro ? $scope.estrutura.sentidoOrdemPadraoFiltro : '';


        $scope.manipularFiltro = function (key, filtro) {
            $scope.filtros[key]['valor'] = '';
        }

        $scope.removerFiltro = function (chave) {
            var novoFiltro = [];
            angular.forEach($scope.filtros, function (val, key) {
                if (key != chave) {
                    novoFiltro.push({
                        'campo': val.campo,
                        'operador': val.operador,
                        'valor': val.valor,
                        'exibir': val.exibir != undefined ? val.exibir : true
                    });
                }
            })
            $scope.filtros = novoFiltro;
            APIServ.salvarDadosLocais(nomeFiltroLocal, novoFiltro);
        }

        $scope.limparFiltros = function () {
            APIServ.apagarDadosLocais(nomeFiltroLocal);
            APIServ.apagarDadosLocais(nomeFiltroTemp);
            $scope.filtros = [];
            $scope.adicionarFiltro();
        }

        if (filtroTemp != null) {
            angular.forEach(filtroTemp, function (filtro, key) {
                $scope.filtros.push(filtro);
            })
        } else if (estrutura.filtrosPadrao) {
            //$scope.filtros = [];
            let tirarPrimeiroFiltro = false;
            angular.forEach(estrutura.filtrosPadrao, function (val, key) {
                let incluirFiltroPadrao = true;
                angular.forEach($scope.filtros, function (valF, keyF) {
                    incluirFiltroPadrao = valF.campo != key || valF.operador != val.operador || valF.valor != val.valor ? true : false;
                })
                if (incluirFiltroPadrao) {
                    if (val.tipo == 'intervaloDatas') {
                        //Depois tenho que inserir as opcoes primeiro e ultimo dia do mes
                        val.di = val.di == 'dataAtual' ? APIAjuFor.dataAtual() : undefined;
                        val.df = val.df == 'dataAtual' ? APIAjuFor.dataAtual() : undefined;
                    }

                    //Vendo se o filtro e visivel, se sim, tiro o primeiro filtro que e em branco, se nao deixo ele
                    if (val.exibir) {
                        tirarPrimeiroFiltro = true;
                    }

                    $scope.filtros.push({
                        campo: key,
                        operador: val.operador,
                        valor: val.valor,
                        exibir: val.exibir != undefined ? val.exibir : false,
                        tipo: val.tipo,
                        texto: val.texto,
                        di: val.di,
                        df: val.df
                    })
                }
            })
            if (tirarPrimeiroFiltro) {
                $scope.filtros.splice(0, 1);
            }
        }

        $scope.converterFiltroParaEnvio = function () {
            let retorno = [];
            angular.forEach($scope.filtros, function (filtro, key) {
                if (filtro.tipo != undefined && filtro.tipo == 'intervaloDatas') {
                    retorno.push({
                        campo: filtro.campo,
                        operador: 'between',
                        valor: filtro.di + '__' + filtro.df,
                        texto: filtro.texto != undefined ? filtro.texto : filtro.campo,
                        tipo: filtro.tipo != undefined ? filtro.tipo : 'varchar'
                    })
                } else {
                    //console.log(filtro);
                    filtro['valor'] = filtro['valor'] != '' ? filtro['valor'].split('--')[0].trim() : '';
                    retorno.push(filtro);
                }
            })

            return retorno;
        }

        if ($scope.filtrar == undefined) {
            $scope.filtrar = FilterFunction;

            if ($scope.estrutura.filtrarAoIniciar != undefined && $scope.estrutura.filtrarAoIniciar && $scope.tela == 'consulta') {
                setTimeout(function () {
                    $scope.filtrar();
                }, 100);
            }
        }

        if ($scope.selecionarItemConsulta == undefined) {
            $scope.selecionarItemConsulta = function (key, item) {
                let parametros = {
                    tela: acao,
                    key: key,
                    campo_chave: $scope.estrutura.campo_chave,
                    chave: item[$scope.estrutura.campo_chave],
                    selecionado: item.selecionado != undefined && item.selecionado
                }
                APIServ.executaFuncaoClasse($scope.baseUrl, 'classeGeral', 'selecionarItemConsulta', parametros).then(function () {

                })
            }
        }
        if ($scope.selecionarTodosItensConsulta == undefined) {
            $scope.selecionarTodosItensConsulta = function () {
                $scope.todosItensSelecionados = $scope.todosItensSelecionados == 'false' || !$scope.todosItensSelecionados;
                if ($scope.listaConsulta != undefined && Object.keys($scope.listaConsulta).length > 0) {
                    let parametros = {
                        tela: acao,
                        selecionado: $scope.todosItensSelecionados
                    }
                    APIServ.executaFuncaoClasse($scope.baseUrl, 'classeGeral', 'selecionarTodosItensConsulta', parametros).then(function () {
                        angular.forEach($scope.listaConsulta, function (item) {
                            item.selecionado = $scope.todosItensSelecionados;
                        })
                    })
                }
            }
        }

        if ($scope.detalhar == undefined) {
            $scope.detalhar = function (key_array) {
                let retorno = [];
                let keyRetorno = 0;
                //Monto o filtro com a chave do item
                let filtros = {
                    tabela: $scope.estrutura.tabela,
                    campo_chave: $scope.estrutura.campo_chave,
                    chave: $scope.listaConsulta[key_array][$scope.estrutura.campo_chave],
                }

                //Vendo se ha tabelas relac
                if ($scope.estrutura.tabelasRelacionadas != undefined) {
                    filtros['tabelasRelacionadas'] = $scope.estrutura.tabelasRelacionadas;
                }

                //Vendo se o detalhe do item ja foi carregado
                if ($scope.listaConsulta[key_array]['detalhes'] == undefined) {
                    APIServ.executaFuncaoClasse($scope.baseUrl, 'classeGeral', 'buscarParaAlterar', filtros).then(function (data) {
                        data = data.data;

                        $scope.listaConsulta[key_array]['arquivosAnexados'] = data.arquivosAnexados;
                        $scope.listaConsulta[key_array]['exibirDetalhes'] = true;
                        $scope.listaConsulta[key_array]['detalhes'] = {};
                        $scope.listaConsulta[key_array]['detalhes'] = data;
                        $scope.$apply();
                    })

                } else {
                    $scope.listaConsulta[key_array]['exibirDetalhes'] = !$scope.listaConsulta[key_array]['exibirDetalhes'];
                }
            }
        }
    }

    if (EGFuncoes.temCadastro(retorno)) {
        if (retorno.variavelValidarAoSubmeter != undefined) {
            $scope[retorno.variavelValidarAoSubmeter] = false;
        }

        if ($scope.salvar == undefined) {
            $scope.salvar = SaveFunction;
        }

        if ($scope.estrutura.tipoEstrutura == 'cadastroUnico') {
            let filtros = {
                tabela: $scope.estrutura.tabela,
                campo_chave: $scope.estrutura.campo_chave,
                chave: 1,
            }

            APIServ.executaFuncaoClasse($scope.baseUrl, 'classeGeral', 'buscarParaAlterar', filtros).then(function (data) {
                $scope[$scope.estrutura.raizModelo] = data.data;
            })
        }

        if ($scope.alterar == undefined) {
            $scope.alterar = function (valor) {
                $rS.carregando = true;
                var indiceVariavel = this.$index;
                let filtros = {
                    tabela: $scope.estrutura.tabela,
                    campo_chave: $scope.estrutura.campo_chave,
                    chave: valor
                }

                if ($scope.estrutura.tabelasRelacionadas != undefined) {
                    filtros['tabelasRelacionadas'] = $scope.estrutura.tabelasRelacionadas;
                }

                APIServ.executaFuncaoClasse($scope.baseUrl, 'classeGeral', 'buscarParaAlterar', filtros).then(function (data) {
                    data['indiceVariavel'] = indiceVariavel;
                    $scope[$scope.estrutura.raizModelo] = data.data;

                    $scope.tela = 'cadastro';
                    $scope.$apply();
                })
            }
        }
    }

    //Fazendo esta comparaca para nao sobrescrever a funcao caso seja declarada no controller
    if ($scope.excluir == undefined) $scope.excluir = DeleteFunction;

    $scope.mostrarInformacoes = (campo) => {
        let elemento = $(event.target);
        let mensagem = $scope.estrutura.campos[campo]['informacoes'];
        let elementoPai = elemento.closest('div.form-group');
        elementoPai.attr('data-toggle', 'popover').attr('data-content', mensagem).popover({
            'placement': 'bottom'
        }).popover('show');
    }

    if (EGFuncoes.temConsulta(retorno)) {
        html += '<search-header></search-header>'; //montaCabecalhoConsulta(retorno, $scope);
        html += `<structure-manager-search-list ng-if="tela == 'consulta'"></structure-manager-search-list>`; //  montaListaConsulta(estrutura);
    }

    //Esta variavel armazenará todas as campos do formulario vazios
    $scope['campo_vazio'] = {};

    if (EGFuncoes.temCadastro(retorno)) {
        html += `<structure-manager-form ng-if="tela == 'cadastro'"></structure-manager-form>`; // montaInicioFormCadastro(retorno, raizModelo);
    }

    if (EGFuncoes.temCadastroDireto(retorno)) {
        $scope.tela = 'cadastro';
        //var usuario = APIServ.BuscarDadosLocais('usuario');
        //$scope.alterar(usuario[retorno.campo_chave]);
    }
    if (retorno.tipoEstrutura == 'somenteCadastro') {
        $scope.tela = 'cadastro';
    }


    if (retorno.tipoEstrutura != 'personalizado') {
        $element.attr('id', 'structureManager');
        $element.html(html);
        $compile($element.contents())($scope);
    }
    
    //$compile($element.contents())($scope.$parent);

    //Vendo se ha filtro salvo para esta pagina, caso sim, carregando
    $scope.filtros = filtroLocal != undefined ? filtroLocal : $scope.filtros;

    if ($scope.aoCarregar != undefined) {
        $scope.aoCarregar();
    }

    //Fiz esta rotina para ver se poe as mascaras nos campos de pesquisa, quando sao padrao
    if ($scope.tela == 'consulta' && estrutura.filtrosPadrao) {
        setTimeout(function () {
            angular.forEach($scope.filtros, function (item, key) {
                if (item.campo != '') {
                    $(`#filtros_${key}_campo`).trigger('change');
                    $scope.filtros[key]['valor'] = estrutura.filtrosPadrao[item['campo']]['valor'] != undefined ? estrutura.filtrosPadrao[item['campo']]['valor'] : '';
                }
            });
            if ($scope.filtros[0]['valor'] == '') {
                $('#filtros_0_valor').focus();
            }
        }, 200);
    }
}

StructureManagerController.$inject = ['$rootScope', '$scope', '$element', '$compile', '$http', 'operadoresConsulta', 'configSettings'];

export default StructureManagerController;
