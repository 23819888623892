import angular from 'angular';

import SearchHeaderFormDefault from './search-header-form-default.directive';
import SearchHeaderController from './search-header.controller'
import SearchHeaderLink from './search-header.link';
import SearchHeaderSelectField from './search-header-select-field';
import './search-header.css';
//import ListaConsulta from './listaConsulta.directive';



function searchHeader() {
  return {
    restrict: 'E',
    controller: SearchHeaderController,
    link: SearchHeaderLink
  }
}

export default angular.module('searchHeader', [ SearchHeaderFormDefault, SearchHeaderSelectField /*ListaConsulta, FormCabecalhoConsulta*/])
  .directive('searchHeader', searchHeader)
  .name;