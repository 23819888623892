import './criaIndex';
import angular from 'angular';
import uiRouter from 'angular-ui-router';

import './app.css';

import '../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import '../../node_modules/bootstrap/dist/js/bootstrap.min.js';
import '../../node_modules/@fortawesome/fontawesome-free/css/all.css';

import baseUrl from './APIBase';

import 'popper.js';

const MODULE_NAME = 'app';
import routing from './app.config';


import cabecalho from 'isilver-panel-header';
import menuPainel from 'isilver-panel-menu';

import Auth from 'isilver-auth-user';
import login from 'isilver-login-page';
import validForm from 'isilver-valid-form';
import users from 'isilver-users-page'

//Importando Paginas
import home from '../pages/home/home';
import imoveisTipos from '../pages/imoveis/imoveis-tipos';
import imoveis from '../pages/imoveis/imoveis';
import clientes from '../pages/clientes/clientes';
import clientesTipos from '../pages/clientes/clientes-tipos';

window.localStorage.setItem('estruturas', JSON.stringify({}));

var app = angular.module('app', [     
    cabecalho,   
    menuPainel,
    validForm,
    uiRouter,
    login,
    users,

    home,
    imoveisTipos,
    imoveis,
    clientesTipos,
    clientes
])
    .run(['$state', '$stateParams', ($rootScope, $state, $stateParams, $base64) => {
        Auth.validarLogin(baseUrl.baseUrl);  
    }])
    .config(routing);

app.constant('configSettings', {
    'baseUrl': baseUrl.baseUrl
}).constant("filtroPadrao", {
    'campo': '',
    'operador': 'like',
    'valor': ''
})
.constant("operadoresConsulta", [{
    valor: "like",
    label: "Contendo"
}, {
    valor: "=",
    label: "Igual"
}, {
    valor: "!=",
    label: "Diferente"
}, {
    valor: ">",
    label: "Maior"
}, {
    valor: ">=",
    label: "Maior ou Igual"
}, {
    valor: "<",
    label: "Menor"
}, {
    valor: "<=",
    label: "Menor ou Igual"
}, {
    valor: "inicial",
    label: "Inicial"
}])

export default MODULE_NAME;
