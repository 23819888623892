import angular from 'angular';

export default angular.module('selects', [])
.directive('selectUf', function() {
    let UFs = ["AC", "AL", "AM", "AP", "BA", "CE", "DF", "ES", "GO", "MA", "MG", "MS", "MT", "PA", "PB", "PE", "PI", "PR", "RJ", "RN", "RO", "RR", "RS", "SC", "SE", "SP", "TO"];
    let html = `
        <select class="form-control">
            <option value="">Estado</option>`;
    angular.forEach(UFs, function(val, key) {
        html += `<option value="${val}">${val}</option>`;
    });
    html += `</select>`
    return {
        restrict: 'E',
        replace: true,
        template: html
    }
})
.directive('selectSexo', function() {
    var ddo = {
        restrict: 'E',
        template: `
        <select class="form-control">
            <option value="">Sexo</option>
            <option value="M">Masculino</option>
            <option value="F">Feminino</option>
        </select>`,
        replace: true
    }
    return ddo;
})
.directive('selectSimNao', function($compile) {
    var ddo = {
        restrict: 'E',
        template: `
        <select class="form-control">
            <option value="S">Sim</option>
            <option value="N">Não</option>
        </select>`,
        replace: true
    }
    return ddo;
})
.directive('selectPessoaTipo', function() {
    var ddo = {
        restrict: "E",
        template: `
        <select class="form-control">
            <option value="">Pessoa</option>
            <option value="F">Física</option>
            <option value="J">Jurídica</option>
        </select>`,
        replace: true
    }
    return ddo;
})
.directive('selectTelefoneOperadora', function() {
    var ddo = {
        restrict: 'E',
        template: `
            <select class="form-control">
                <option value="">Operadora</option>
                <option value="FIXO">Fixo</option>
                <option value="OI">OI</option>
                <option value="VIVO">Vivo</option>
                <option value="TIM">Tim</option>
                <option value="CLARO">Claro</option>
                <option value="NEXTEL">Nextel</option>
            </select>`,
        replace: true
    }
    return ddo;
})
.directive('selectOperadores', function() {
    var ddo = {
        restrict: 'E',
        template: `
            <select class="form-control">
            <option value="">Selecione</option>
                <option value="like">Contendo</option>
                <option value="=">Igual</option>
                <option value="!=">Diferente</option>
                <option value=">">Maior</option>
                <option value=">=">Maior ou Igual</option>
                <option value="<">Menor</option>
                <option value="<=">Menor ou Igual</option>
            </select>`,
        replace: true
    }
    return ddo;
})
.name;