import MODULE_NAME from ".";

var viewPort = document.createElement('meta');
viewPort.setAttribute('name', 'viewport');
viewPort.setAttribute('content', 'width=device-width, initial-scale=1');
document.head.appendChild(viewPort);

var element = document.createElement('div');
element.setAttribute('ng-app', 'app');
element.setAttribute('class', 'app');

var elementBase = document.createElement('base');
elementBase.setAttribute('href', '/');
element.appendChild(elementBase);

var cabecalho = document.createElement('cabecalho');
cabecalho.setAttribute('class', 'cabecalho');
element.appendChild(cabecalho);

var view = document.createElement('ui-view');
view.setAttribute('class', 'corpo');
element.appendChild(view);


var menuPainel = document.createElement('menu-painel');
menuPainel.setAttribute('class', 'menuPainel');
element.appendChild(menuPainel);

var rodape = document.createElement('div');
rodape.setAttribute('class', 'rodape');
element.appendChild(rodape);

document.body.appendChild(element);


var divModal = document.createElement('div');
divModal.setAttribute('id', 'modalSimples');
divModal.setAttribute('class', 'modal fade');
divModal.setAttribute('role', 'dialog');
divModal.innerHTML = `

    <div class="modal-dialog">
    <!-- Modal content-->
    <div class="modal-content">
        <div class="modal-header">          
        <h4 class="titulo-modal"></h4>
        </div>
        <div class="modal-body">
        <p><i class="fa fa-spin fa-spinner"></i> Aguarde...</p>
        <p></p>
        </div>
        <div class="modal-footer">        
        </div>
    </div>
    </div>`;

document.body.appendChild(divModal);

var divPopUp = document.createElement('div');
divPopUp.setAttribute('id', 'divPopUp');
document.body.appendChild(divPopUp);