import ClienteTipoController from './clientes-tipos.controller';

routes.$inject = ['$stateProvider'];

export default function routes($stateProvider) {
  $stateProvider    
    .state('clientes-tipos', {
      url:'/clientes-tipos',
      template: '<structure-manager></structure-manager>',
      controller: ClienteTipoController,
      controllerAs: 'clienteTipoCtrl',     
      estrutura: require('./clientes-tipos.tmpl.json')
    })
    .state('clientes-tipos/cadastro', {
      url:'/clientes-tipos/cadastro',
      template: '<structure-manager></structure-manager>',
      controller: ClienteTipoController,
      controllerAs: 'clienteTipoCtrl',     
      estrutura: require('./clientes-tipos.tmpl.json')
    })
}

