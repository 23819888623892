import angular from 'angular';
import uiRouter from 'angular-ui-router';

import routing from './clientes-tipos.routes';
import ClienteTipoController from './clientes-tipos.controller';

import APIServ from 'isilver-services';

import StructureManager from 'isilver-structure-manager';
export default angular.module('app.clientes-tipos', [uiRouter, StructureManager])
  .config(routing)
  .controller('ClienteTipoController', ClienteTipoController)
  .run(() => {
    APIServ.salvarEStrutura('clientes-tipos', require('./clientes-tipos.tmpl.json'));
  })
  .name;