const $ = require('jquery');

const dataAtual = (acao, dias) => {
  let dt = new Date();

  if (acao != undefined && acao == 'subtrair') {
      dt.setDate(dt.getDate() - dias);
  } else if (acao != undefined && acao == 'somar') {
      dt.setDate(dt.getDate() + dias);
  }

  dt = dt.toISOString().slice(0, 10);
  let temp = dt.split('-');
  return temp[2] + '/' + temp[1] + '/' + temp[0];  
}

const primeiroDiaMes = () => {
  let dt = new Date().toISOString().slice(0, 10);
  let temp = dt.split('-');
  return '01/' + temp[1] + '/' + temp[0];
}

const horaAtual = () => {
  let temp = new Date();
  let hr = temp.getHours();
  hr = hr.toString().length == 1 ? '0' + hr : hr;
  let min = temp.getMinutes();
  min = min.toString().length == 1 ? '0' + min : min;
  return hr + ':' + min;
}


const timeStampParaTela = valor =>{
    let temp = valor.split(' ');
    let dt = temp[0].split('-');
    let hr = temp[1].substr(0, 5);
    let retorno = `${dt[2]}/${dt[1]}/${dt[0]} ${hr}`;
    return retorno;
}

const montarTimeStamp = (data, hora) =>{
    let temp = data.split('/');
    let dt = temp[2] + '-' + temp[1] + '-' + temp[0];
    hora = hora.length == 8 ? hora : hora + ':00';
    return dt + ' ' + hora;
}

const timeStampAtual = () => {
    return montarTimeStamp(dataAtual(), horaAtual());
}

const adicionarMeses = (data, qtdMeses) => {
  let temp = data.split('/');
  let dia = temp[0];
  let mes = temp[1];
  let ano = temp[2];
  let dataTemp = new Date(`${mes}/${dia}/${ano}`);
  let somar = parseInt(qtdMeses) + parseInt(mes) - 1;
  dataTemp.setMonth(somar);
  if (dataTemp != 'Invalid Date') {
      return dataTemp;
  }
}

const numberFormat = (valor, casas_decimais = 2, separador_decimal = ',', separador_milhar = '.') => {
  valor = (valor + '').replace(/[^0-9+\-Ee.]/g, '');
  let n = !isFinite(+valor) ? 0 : +valor,
      prec = !isFinite(+casas_decimais) ? 0 : Math.abs(casas_decimais),
      sep = (typeof separador_milhar === 'undefined') ? ',' : separador_milhar,
      dec = (typeof separador_decimal === 'undefined') ? '.' : separador_decimal,
      s = '',
      toFixedFix = function (n, prec) {
          let k = Math.pow(10, prec);
          return '' + Math.round(n * k) / k;
      };
  // Fix for IE parseFloat(0.55).toFixed(0) = 0;
  s = (prec ? toFixedFix(n, prec) : '' + Math.round(n)).split('.');
  if (s[0].length > 3) {
      s[0] = s[0].replace(/\B(?=(?:\d{3})+(?!\d))/g, sep);
  }
  if ((s[1] || '').length < prec) {
      s[1] = s[1] || '';
      s[1] += new Array(prec - s[1].length + 1).join('0');
  }
  return s.join(dec);
}

const caracteresValidosDecimais = valorEntrada => {
  if (valorEntrada == undefined) {
      return '';
  }

  let retorno = valorEntrada.toString();
  let retornoTemp = retorno.replace(/[^\d\,]/g, "");
  retorno = '';
  let temVirgula = false;
  for (var i = 0; i < retornoTemp.length; i++) {
      let caracter = retornoTemp.substr(i, 1);
      retorno += temVirgula && caracter == ',' ? '' : caracter;
      if (caracter == ',') {
          temVirgula = true;
      }
  }
  return retorno;
}

const decimais = (valorEntrada, casas = 2, maximo = '0') => {
  if (valorEntrada == undefined) {
      return '';
  }
  let valor = valorEntrada.split(',');
  let qtd = Object.keys(valor).length;
  let decimais = 0;

  if (qtd > 1) {
      decimais = valor[qtd - 1];
      valor.splice(qtd - 1, 1);
  }

  valor = valor.join('');
  valor = valor.length > 0 ? valor : 0;

  let qtdDecimais = decimais.toString().length;
  if (casas > qtdDecimais) {
      for (var i = qtdDecimais; i < casas; i++) {
          decimais += '0';
      }
  } else if (qtdDecimais > casas) {
      decimais = decimais.toString().substr(0, casas);
  }

  var qtdLoop = (valor.length - 3) / 3;
  var count = 0;
  while (qtdLoop > count) {
      count++;
      valor = valor.replace(/(\d+)(\d{3}.*)/, "$1.$2");
  }

  let retorno = valor + ',' + decimais;

  var eNumero = retorno != undefined && textoParaFloat(retorno) > 0;

  if (eNumero && textoParaFloat(maximo) > 0) {
      let retornoFloat = textoParaFloat(retorno);
      var valorMaximo = textoParaFloat(maximo);
      return !maximo ? retorno : retornoFloat <= valorMaximo ? retorno : maximo;
  } else {
      return retorno;
  }
}

const somarTexto = (valor1, valor2) => {
  let v1 = valor1 != undefined ? textoParaFloat(valor1) : 0;
  let v2 = valor2 != undefined ? textoParaFloat(valor2) : 0;  
  return numberFormat(v1 + v2, 2, ',', '.');
}

const subtrairTexto = (valor1, valor2) =>{
  let v1 = valor1 != undefined ? textoParaFloat(valor1) : 0;
  let v2 = valor2 != undefined ? textoParaFloat(valor2) : 0;
  return numberFormat(v1 - v2, 2, ',', '.');
}

const textoParaFloat = function (valor) {
  let retorno = 0;
  if (valor != '') {
      let temp = valor;
      temp = temp != undefined ? temp.replace('.', '') : temp;
      temp = temp != undefined ? temp.replace(',', '.') : temp;
      retorno = temp;
  }
  return parseFloat(retorno);
};

const formata = function (valor, casasDecimais) {
    return numberFormat(valor, casasDecimais, ',', '.');    
}

const diretivaParaVariavel = function(str) {
  return str.replace(/(\-\w)/g, function (m) {
      return m[1].toUpperCase();
  });
}

const variavelParaDiretiva = function(str) {
  return str.replace(/([A-Z])/g, function ($1) {
      return "-" + $1.toLowerCase();
  });
}

const numeroPar = function (numero) {
  return (numero / 2) == 0;
}

const montaMensagem = function (tipo, campo) {
  switch (tipo) {
      case 'igual':
          return 'Valor Diferente de: ' + campo;
          break;
      case 'invalido':
          return 'Valor Invalido!'
          break;
      default:
          return 'Obrigatorio!';
  }
}

const manipularSpan = function (elementoPai, mensagem, erro, obrigatorio) {    
  let elementoTexto = $(elementoPai).find('.form-control');
  let asterisco = $(elementoTexto).attr('required') != undefined && $(elementoTexto).attr('required') ? '*' : '';
  let elementoInserir = $(elementoPai).children('label');
  let template = `<span class="asterisco"> ${asterisco} ${mensagem}</span>`;
  $(elementoPai).find('span.asterisco').remove();
  $(template).appendTo(elementoInserir);    
  $(elementoPai).toggleClass('erro', erro);
}

module.exports = {
  adicionarMeses: adicionarMeses,  
  caracteresValidosDecimais: caracteresValidosDecimais,
  dataAtual: dataAtual,
  decimais: decimais,
  diretivaParaVariavel: diretivaParaVariavel,
  formata: formata,
  horaAtual: horaAtual,
  manipularSpan: manipularSpan,
  montarTimeStamp: montarTimeStamp,
  numberFormat: numberFormat,
  numeroPar: numeroPar,
  primeiroDiaMes: primeiroDiaMes,
  somarTexto: somarTexto,
  subtrairTexto: subtrairTexto,
  textoParaFloat: textoParaFloat,
  timeStampAtual: timeStampAtual,
  timeStampParaTela: timeStampParaTela,
  variavelParaDiretiva: variavelParaDiretiva 
};