import angular from 'angular';
import uiRouter from 'angular-ui-router';

import routing from './clientes.routes';
import ClienteController from './clientes.controller';

import StructureManager from 'isilver-structure-manager';
import APIServ from 'isilver-services';

export default angular.module('app.clientes', [uiRouter, StructureManager])
  .config(routing)
  .controller('ClienteController', ClienteController)
  .run(() => {
    APIServ.salvarEStrutura('clientes', require('./clientes.tmpl.json'));
  })
  .name;